<template>
  <div class="px-2 py-2.5">
      <div class="w-full py-5 text-lg font-semibold text-center">Clients Trades Population</div>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
      <TradeDistribution />
      <TransactionDensity />
    </div>
  </div>
</template>

<script>
import TransactionDensity from "../components/TransactionDensity.vue";
import TradeDistribution from "../components/TradeDistribution.vue";

export default {
  components: {
    TransactionDensity,
    TradeDistribution,
  },
};
</script>
