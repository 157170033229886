export default class AccountDetail {
    constructor() {
        Spruce.store('__account', {
            open: false,
            detail: {
                currency: "",
                investor_password: "",
                name: "",
                platform_login: 0,
                platform_password: "",
                platform_server: "",
                platform_server_ip: "",
            }
        });

        window.addEventListener('showdetail', ({detail}) => this.handle(detail));
    }
    handle(detail) {
        this.$store.__account.open = true;
        this.$store.__account.detail = detail;
    }
}