const intlTelInput = require("intl-tel-input");

window.registration = function () {
  const input = $('input[type="tel"]');
  if (input.length > 0) {
    const telInput = intlTelInput(input[0], {
      formatOnDisplay: false,
      separateDialCode: false,
      autoHideDialCode: true,
      nationalMode: false,
      initialCountry: "auto",
      autoPlaceholder: 'aggressive',
      placeholderNumberType: "FIXED_LINE",
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.6/js/utils.js",
      geoIpLookup: function (success, failure) {
        $.ajax("/geo?location=current")
          .then(function (res) {
            var countryCode = res && res.countryCode ? res.countryCode : "us";
            success(countryCode);
          })
          .fail(function () {
            failure("us");
          });
      },
    });
    telInput.promise.then(function () {
      reformatPlaceholder();
    });
  }


  //correct telinput formatting
  input.on('focusout focusin', function () {
    reformatPlaceholder();
  });

  const _this = {
    phone: null,
    init: function () {

    },
    placeHolderChanged: function () {
      let phone = this.$refs.phone;
      var number = phone.placeholder;


    },
  };

  return _this;
}

registration();

function reformatPlaceholder() {
  var tel = $('input[type="tel"]');
  if (tel.length > 0) {
    var placeholder = tel.attr('placeholder');
    tel.attr('placeholder', placeholder.replace(/[\-\s]/g, ''));
  }
  return placeholder;
}
