import Vue from 'vue';
import Clients from "../vue/Clients.vue";
import '../client/composition-api';

const clients = document.getElementById('clients');
if (clients != undefined) {
  const app = new Vue({
    components: {
      Clients,
    }
  });

  app.$mount(clients);
}
