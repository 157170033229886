import Spruce from "@ryangjchandler/spruce";
const pdfMake = require("pdfmake/build/pdfmake");
import moment from "moment";

const makeCell = (content, rowIndex = -1, options = {}) => {
  return Object.assign({ text: content, fillColor: rowIndex % 2 ? 'white' : '#e8e8e8' }, options);
}

const thl = (content, rowIndex = -1, options = {}) => {
  return makeCell(content, rowIndex, Object.assign({ bold: true, alignment: 'left', fontSize: 9 }, options));
}

const tdl = (content, rowIndex = -1, options = {}) => {
  return makeCell(content, rowIndex, Object.assign({ bold: false, alignment: 'left', fontSize: 9 }, options));
}

// -- Create a base document template for the reports.
const createDocumentDefinition = (subHeading, ...contentParts) => {
  const baseDocDefinition = {
    pageSize: 'A4',
    footer: (currentPage, pageCount) => {
      return {
        text: `${moment().format('MMMM d, Y')} : Page ${currentPage.toString()} of ${pageCount.toString()}`,
        alignment: 'center',
        fontSize: 7
      }
    },

    styles: {
      title: {
        fontSize: 24
      },
      titleSub: {
        fontSize: 10
      },
      titleDate: {
        fontSize: 14,
        alignment: 'right',
        bold: true
      }
    },

    content: [
      {
        columns: [
          { text: 'Deposit Information', style: 'title', width: '*' },
          { text: moment().format('MMMM d, Y'), style: 'titleDate', width: '160' },
        ]
      },
      { text: `${subHeading}\n\n`, style: 'titleSub' },
    ],
  };
  const docDefinition = JSON.parse(JSON.stringify(baseDocDefinition));
  docDefinition.footer = baseDocDefinition.footer;
  docDefinition.content.push(...contentParts);
  return docDefinition;
};



window.depositDetail = function () {
  return {
    printPdf: async function (dom) {

      let data = [];

      var row = $('.row', dom);
      if (row.length > 0) {
        row.each(function (__, element) {
          data.push({ col1: $('.col:first', element).text().trim(), col2: $('.col:last', element).text().trim() });
        });
      }
      // -- Generate the body of the document table, with headings
      const tableBody = (dataRows) => {
        const body = [
          [
            thl(' ', -1, { colSpan: 2 }),
            thl(' '),
          ],
          []
        ];

        dataRows.forEach((row, index) => {
          const tableRow = [];
          tableRow.push(tdl(row['col1'], index));
          tableRow.push(tdl(row['col2'], index));
          body.push(tableRow);
        });
        body[1] = [
          tdl(' ', -1, { colSpan: 2, fillColor: 'black', color: 'white' }),
          tdl(' '),
        ];
        return body;
      }

      // -- The main report table, with the table body.
      const tableData = {
        table: {
          headerRows: 1,
          widths: ['*', '*'],
          body: tableBody(data),
        }
      };
      const docDefinition = createDocumentDefinition('Personal details and Deposit information.', tableData);
      pdfMake.createPdf(docDefinition).download(`memberRegister-${moment().format('MMMM d, Y')}.pdf`);

    },
  };
};

window.modalView = function () {
  var _this = Spruce.store("modalView", {
    open: false,
    loading: true,
    content: "",
    init: function (token) {
      $.ajaxSetup({
        headers: {
          "X-CSRF-TOKEN": token,
        },
      });
    },
    onClose: function () {
      this.open = false;
      this.loading = true;
    },
    handler: function (detail) {
      this.open = true;

      $.ajax({
        url: detail.url,
        success: function (response) {
          _this.loading = false;
          _this.content = response;
        },
      });
    },
  });

  return _this;
};

window.showModalView = function (href) {
  window.dispatchEvent(
    new CustomEvent("modal-view", { detail: { url: href } })
  );
};

window.closeModal = function(){
  window.dispatchEvent(
    new CustomEvent("close-modal", { detail: { close: true } })
  );
};