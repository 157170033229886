const zxcvbn = require("zxcvbn");

//{'border-gray-100': $store.password_strength._default, 'border-red-400': $store.password_strength.weak}
window.passwordStrength = function () {
  var password_strength = Spruce.store("password_strength", {
    password: null,
    _default: true,
    weak: false,
    mid: false,
    strong: false,
    len: 0,

    indicator: function () {
      return {
        "border-gray-100": password_strength._default,
        "border-red-400": password_strength.weak && password_strength.len > 0,
        "border-yellow-400": password_strength.mid,
        "border-green-400": password_strength.strong,
      };
    },
  });

  Spruce.watch("password_strength.password", function (value) {
    if (value) {
      score = zxcvbn(value);
      console.log(score);
    }
  });

  var _this = {
    passwordScore: 0,
    password: '',
    checkStrength: function() {
        if(!_this.password) return _this.passwordScore = 0;
        _this.passwordScore = zxcvbn(_this.password).score + 1;
        console.log(_this.passwordScore, _this.password);
    },
  };

  return _this;
};
