import "jquery-validation";

window.compliance = function () {
    return {
        tab: 'tab-1',
        init: function () {
            this.form1 = $('#financial-information-form');
            $(this.form1).validate({
                errorElement: "small",
                errorClass: "text-sm text-red-600",
                messages: {
                    "employment_status": "*Please select an option",
                    "industry": "*Please select an option",
                    "business_field": "*Please select an option",
                    "job_title": "*Please provide job title",
                    "has_worked_in_finance": "*Please provide a Yes or No answer",
                    "position_occupation": "*Please provide a Yes or No answer",
                    "is_benecial_owner": "*Please provide a Yes or No answer",
                    "more_info_capital_source": "*Please enter a valid answer",
                    "acknowledgement": "*Please accept acknowledgement",
                },
                submitHandler: (form) => this.onSumbitForm1(form, "ind1")
            });
            $('#investment-objectives').validate({
                errorElement: "small",
                errorClass: "text-sm text-red-600",
                messages: {
                    "trading_experience": "*Please select work experience",
                    "length_of_activity": "*Please select length of activity",
                    "years_of_experience": "*Please select trading experience",
                },
                submitHandler: (form) => this.onSumbitForm1(form, "ind2")
            });
            $('#declarations').validate({
                errorElement: "small",
                errorClass: "text-sm text-red-600",
                messages: {
                    "offence_conviction": "*Please provide a Yes or no answer",
                    "political_exposed": "*Please provide a Yes or no answer",
                },
                submitHandler: (form) => this.onSumbitForm1(form, "ind3")
            });
        },

        onBenefitOptionChange: function (event) {
            var additional_option = this.$refs.additional_option;
            var options = event.target.options;
            var selection = parseInt(options[options.selectedIndex].value);
            additional_option.style.display = selection == 1 ? 'none' : 'block';
        },
        onSumbitForm1: function (form, container) {
            var button = $(`#${container} button`);
            var done = $(`#${container} div`);
            var old = button.text();
            button.text('saving...');
            $.ajax({
                url: form.action,
                data: $(form).serialize(),
                method: form.method,
                success: function (res) {
                    button.text(old);
                    button.prop('disabled', true);
                    done.css('display', 'block');
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                },
                error: function (res) {
                    button.text(old);
                    notify('error', res.responseJSON);
                }
            })
        },
        change: function(event){
            var options = event.target.options;
            this.tab = String(options[options.selectedIndex].value).trim();
        }
    };
};