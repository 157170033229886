<template>
  <div ref="chart1"></div>
</template>

<script>
import { ref } from "vue";
import Highcharts from "highcharts";
import axios from "axios";

export default {
  mounted() {
    const config = {
      headers: {
        "X-CSRF-TOKEN": window._token,
      },
    };

    const request = axios.get("/api/ib/trade-distribution", config);
    request.then((response) => {
      var options = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
        },
        title: {
          text: "",
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        series: [
          {
            name: "Brands",
            colorByPoint: true,
            data: response.data,
          },
        ],
      };
      Highcharts.chart(this.$refs.chart1, options);
    });
  },
  methods: {
    getData() {
      return [];
    },
  },
};
</script>