import { bindAll, isUndefined } from "lodash";


window.newsHandler = function () {
    var _this = {
        open: false,
        init: function (sessionData) {
            // if (!isUndefined(sessionData)) {
            //     this.open = sessionData.show;
            // }
        },
        handleEvent: function () {
            this.open = !this.open;
            // console.log('I saw a news');
        }
    }

    return bindAll(_this);
}