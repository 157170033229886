import _, { bindAll } from "lodash";
var creditcardutils = require('creditcardutils');
var validator = require('validator');

window.card_payment = function () {

    var _this = {
        cardNumber: null,
        _card_photo_init: '_selected_card_inid',
        refs: null,
        cardType: "",
        init: function () {
            this.$refs.expiry && this.$refs.expiry.setCustomValidity("Please provide valid expiry");
            this.$refs.card_number && this.$refs.card_number.setCustomValidity("Please provide card number");
            this.$refs.cvc && this.$refs.cvc.setCustomValidity("Please provide CVC number");
            this.$refs.card_name && this.$refs.card_name.setCustomValidity("No card name present");
            this.$refs.email && this.$refs.email.setCustomValidity("Please provide a valid Email");
            // this.$refs.amount && this.$refs.amount.setCustomValidity("Please provide a valid amount");
        },
        onCardInput: function (elem) {
            var value = this.cardNumber = $(elem).val();
            var card_box_id = this._card_photo_init;
            var type = this.cardType = creditcardutils.parseCardType(value);
            this.$refs.card_type.value = type;

            var allSvg = $(`#${card_box_id} [data-type]`);
            if (allSvg.length > 0 && allSvg.hasClass('opacity-25')) {
                allSvg.removeClass('opacity-25');
            }
            if (type != null) {
                var selectedSvg = $(`#${card_box_id} :not([data-type="${type}"])`);
                if (selectedSvg.length > 0) {
                    selectedSvg.addClass('opacity-25');
                }
            }
            $(elem).val(creditcardutils.formatCardNumber(value));

            if (value == null || value == '') {
                elem.setCustomValidity("Please provide card number");
            }
            if (creditcardutils.validateCardNumber(value) || value != "") elem.setCustomValidity("");
        },
        onExpiryEntered: function () {
            var value = this.expiry = this.$refs.expiry.value;
            this.$refs.expiry.value = creditcardutils.formatCardExpiry(this.expiry);
            var expiryDate = creditcardutils.parseCardExpiry(value);
            this.$refs.expiryDate.value = JSON.stringify(expiryDate);

            if (value == null || value == '') {
                this.$refs.expiry.setCustomValidity("Please provide card expiry");
            } else if (!creditcardutils.validateCardExpiry(expiryDate.month, expiryDate.year)) {
                this.$refs.expiry.setCustomValidity("Invalid card expiry");
            }
            if (creditcardutils.validateCardExpiry(expiryDate.month, expiryDate.year)) this.$refs.expiry.setCustomValidity("");
        },
        onCvcEntered: function () {
            var value = this.$refs.cvc.value;
            if (value == null || value == '') {
                this.$refs.cvc.setCustomValidity("Please provide card CVC");
            } else if (!creditcardutils.validateCardCVC(value, this.cardType)) {
                this.$refs.cvc.setCustomValidity("Invalid card CVC");
            }
            if (creditcardutils.validateCardCVC(value, this.cardType)) this.$refs.cvc.setCustomValidity("");
        },
        onCardName: function () {
            var value = this.$refs.card_name.value;
            if (value == null || value == '') {
                this.$refs.card_name.setCustomValidity("No card name present");
            } else if (value.length > 0) {
                this.$refs.card_name.setCustomValidity("");
            }
        },
        onEmailEntered: function () {
            var value = this.$refs.email.value;
            if (value == null || value == '') {
                this.$refs.email.setCustomValidity("Please provide a valid Email");
            } else if (!validator.isEmail(value)) {
                this.$refs.email.setCustomValidity("Invalid Email provided");
            }
            if (validator.isEmail(value)) {
                this.$refs.email.setCustomValidity("");
            }
        },
    };

    bindAll(_this);
    return _this;
}