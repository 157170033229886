import { bindAll, defaultsDeep, isNull, isUndefined } from "lodash";
import Spruce from "@ryangjchandler/spruce";

window.contactUsForm = function (token) {
    Spruce.store('contact_form', {
        loading: true,
    });

    return {
    }
};

window.contact = function (token) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': token
        }
    });

    var _this = {
        rows: [],
        selected: '',
        init: function (data) {
            if (!isNull(data)) {
                notify(data.type, {
                    title: data.title,
                    message: data.message,
                });
            }
        },
        showMenu: function (index) {
            if (this.selected != `item_${index}`) {
                this.selected = `item_${index}`;
                return;
            }
            this.selected = '';
        },
        onCloseCase: function (url, id, token) {
            var response = $.ajax({
                url: url,
                // method: 'post',
                type: 'delete',
                dataType: "JSON",
                data: {
                    "id": id,
                    "_method": 'DELETE',
                    "_token": token,
                },
                success: function (data) {
                    console.log(data);
                    if (!isNull(data)) {
                        notify(data.type, {
                            title: data.title,
                            message: data.message,
                        });
                    }
                    window.location.reload();
                }
            });
        }
    }

    bindAll(_this);
    return _this;
}

window.showCaseMessages = function () {
    var _this = {
        html: "",
        id: "",
        open: false,
        url: "",
        handler: function (detail) {
            this.open = !this.open;
            this.id = detail.caseId;

            var response = $.ajax({
                async: false,
                url: detail.url,
            });

            this.html = response.responseText;
        },
        loadBody: function () {
            return this.html;
        }
    };

    bindAll(_this);
    return _this;
}

window.showCaseModal = function (url, caseId) {
    window.dispatchEvent(
        new CustomEvent("view-case", { detail: { url: url, caseId: caseId } })
    );
}

window.categoryListItem = function (token) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': token
        }
    });

    var category = Spruce.store('category', {
        problems: [],
        showCategory: false,
        activeItem: null,
        selectedItem: null,
        selectedName: null,
        loading: true,
        onArrowDown: function () {
            var itemLength = $(this.$refs.dropdown).children('li').length;
            if ((this.activeItem + 1) < itemLength) {
                this.activeItem++;
            }
        },
        onArrowUp: function () {
            if (this.activeItem > 0)
                this.activeItem--;
        },
        onOptionSelected: function () {
            this.selectedItem = this.activeItem;
            this.selectedName = this.getSelectedText(this.selectedItem);
        },
        onOptionSelectAndClose: function () {
            this.showCategory = false;
            this.onOptionSelected();
        },
        onEscape: function () {
            this.showCategory = false;
        },
        getSelectedText: function (index) {
            if (!isUndefined(this.problems[index])) {
                return this.problems[index];
            }
            return "";
        }
    });

    var _this = {
        showCategory: false,
        activeItem: null,
        selectedItem: null,
        selectedName: "",
        categories: [],
        init: function (url) {
            var response = $.ajax({
                url: url,
                async: false,
            });

            var category = Object.entries(response.responseJSON || {})

            if (!isNull(category) && !isUndefined(category)) {
                this.categories = category;
            }

            this.activeItem = 0;
            this.onOptionSelected();
            Spruce.store('contact_form').loading = false;
            var preloader = document.getElementById('preloader_ind');
            var contact_form = document.getElementById('contact_form');
            if (!isNull(preloader) && !isNull(contact_form)) {
                preloader.remove();
                contact_form.style.display = 'block';
            }
        },
        onArrowDown: function () {
            var itemLength = $(this.$refs.dropdown).children('li').length;
            if ((this.activeItem + 1) < itemLength) {
                this.activeItem++;
            }
        },
        onArrowUp: function () {
            if (this.activeItem > 0)
                this.activeItem--;
        },
        onOptionSelected: function () {
            this.selectedItem = this.activeItem;
            this.selectedName = this.getSelectedText(this.selectedItem);
            category.selectedItem = 0;
            category.activeItem = 0;
            category.onOptionSelected();
        },
        onOptionSelectAndClose: function () {
            this.showCategory = false;
            this.onOptionSelected();
        },
        onEscape: function () {
            this.showCategory = false;
        },
        getSelectedText: function (index) {
            if (!isUndefined(this.categories[index])) {
                this.activeItem = null;
                category.problems = this.categories[index][1];
                return this.categories[index][0];
            }
        }
    }
    bindAll(_this);
    return _this;
}
