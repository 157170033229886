window.$ = window.jQuery = require("jquery");
import "./bootstrap";
require("bootstrap");
window.toastr = require("toastr");
require("selectize");
require("sweetalert");
require("bootstrap-pincode-input");
require("intl-tel-input");
require("validator");
require("viewerjs");
require("tinymce");
require("moment");
require("jquery-datetimepicker");
require("jszip");
require("pdfmake");
require("bootstrap-switch");
require("../views/lbc/assets/js/app");
import "../lib/datetimepicker/jquery.datepicker2";
import "dropzone";
import "clipboard";
import "lightbox2";
import './client/dashboard';
import './client/client-table';

window.printJS = require("print-js");
import './admin/newFile';
// const OverlayScrollbars = require('overlayscrollbars');
window.echarts = require("echarts");

$.ajaxSetup({
  headers: {
    "X-CSRF-TOKEN": $('meta[name="token"]').attr("content"),
  },
});

window.makeid = function (length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};


import "selectize";
import tippy from "tippy.js";
import "../lib/dtsel/dtsel";
import PerfectScrollbar from "perfect-scrollbar";
import "@ryangjchandler/spruce";
import "./admin/notification";
import "./admin/webinar";
import "./admin/seminars";
import "./admin/single_mail_composer";
import "./admin/payment_gateway";
import "./admin/promotions";
import "./admin/trading_settings";
import "./admin/accounts";
import "./admin/manage-verification";
import "./admin/withdrawal-request";
import "./admin/notification-settings";
import "./admin/deposit-request";
import "./admin/box-mailer";
import "./admin/modal-view";
import "./admin/ManagerUsers";
import "./admin/BlockAccount";
import "./admin/DeleteAccount";
import "./admin/withdrawal-history";
import "./admin/deposit-history";
import "./admin/refund";
import "./admin/refund-request";
import "./admin/bankwire-request";
import "./admin/composer.js"
import './client/users/cryptoWithdraw';

$(function () {
  //switch
  var $switch = $(".switch");
  if ($switch.length > 0) {
    $switch.each((__, element) => {
      $(element).bootstrapSwitch();
    });
  }

  var $datetime = $('[type="datetime"]');
  if ($datetime.length > 0) {
    new dtsel.DTS($datetime[0], { dateFormat: "dd-mm-yyyy", showTime: true });
  }

  var $date = $(".date");
  if ($date.length > 0) {
    new dtsel.DTS($date[0], { dateFormat: "dd-mm-yyyy" });
  }

  var $tooltip = $("[data-tooltip]");
  if ($tooltip.length > 0) {
    $tooltip.each(function (__, element) {
      var $self = $(element);
      var content = $self.attr("tooltip-title");
      var placement = $self.attr("tooltip-placement");

      if (content !== undefined) {
        var instance = tippy(element, {
          content: content,
          theme: "translucent",
        });
        if (placement !== undefined) instance.props.placement = placement;
      }
    });
  }

  tinymce.init({
    selector: "textarea.richtext",
    height: 500,
    theme: "modern",
    plugins:
      "print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount contextmenu colorpicker textpattern help",
    toolbar1:
      "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | code",
    image_advtab: true,
  });

  //handle x-dropdown's
  var $dropdown = $(".dropdown-menu > a");
  if ($dropdown.length > 0) {
    $dropdown.each(function (__, child) {
      //search parent and seek route
      var $thisParent = $dropdown.parents(".dropdown");
      if ($thisParent.length > 0) {
        var $__targetButton = $thisParent.find("button");
        if ($__targetButton.length > 0) {
          $(child).on("click", function (e) {
            e.preventDefault();
            var href = $__targetButton.attr("href");
            var method = $__targetButton.attr("method");
            var success = eval(`(${$__targetButton.attr("onsuccess")})`);
            var error = eval(`(${$__targetButton.attr("onerror")})`);
            var token = $__targetButton.attr("token");
            if (href != null && href !== undefined) {
              var data = new FormData();
              data.set("action", $(this).text().trim().toLowerCase());
              $.ajax({
                url: href,
                method: method,
                data: data,
                processData: false,
                contentType: false,
                headers: {
                  "X-CSRF-TOKEN": token,
                },
                success: function (res) {
                  success(res);
                },
                error: function (err) {
                  error(err);
                },
              });
            }
          });
        }
      }
    });
  }

  //obsolete
  const input = $('input[type="phone"]');
  if (input.length > 0) {
    const it = intlTelInput(input[0], {
      formatOnDisplay: false,
      separateDialCode: false,
      autoHideDialCode: true,
      nationalMode: true,
      initialCountry: "auto",
      preferredCountries: ["us", "gb", "br", "ru", "cn", "es", "it"],
      autoPlaceholder: "aggressive",
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.6/js/utils.js",
      geoIpLookup: function (success, failure) {
        $.ajax("http://ip-api.com/json").then(function (res) {
          var countryCode = res && res.countryCode ? res.countryCode : "us";
          success(countryCode);
        });
      },
    });
    input.on("countrychange", function () {
      var data = it.getSelectedCountryData();
      var inputDialCode = $('input[name="dial_code"]');
      if (inputDialCode.length > 0) {
        var dialCode = "+";
        dialCode += data.dialCode;
        inputDialCode.val(dialCode);
      }
    });
    $("[data-stronger]").password();
    $("#country-code").on("change", function () {
      it.setCountry($(this).val());
    });
  }


  //email validator
  $(".input-indicator input").on("change", function () {
    const parent = $(this).parent();
    if (parent.hasClass("email")) {
      if (isEmail($(this).val())) {
        parent
          .find("i")
          .attr("class", "fas")
          .removeClass("d-none")
          .addClass("fa-check")
          .addClass("text-success");
      } else {
        parent
          .find("i")
          .attr("class", "fas")
          .removeClass("d-none")
          .addClass("fa-times-circle")
          .addClass("text-danger");
      }
    }
    if (parent.hasClass("plain")) {
      console.log($(this).val());
      if (isAlpha($(this).val())) {
        parent
          .find("i")
          .attr("class", "fas")
          .removeClass("d-none")
          .addClass("fa-check")
          .addClass("text-success");
      } else {
        parent
          .find("i")
          .attr("class", "fas")
          .removeClass("d-none")
          .addClass("fa-times-circle")
          .addClass("text-danger");
      }
    }
  });
});
