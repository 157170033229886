import Vue from 'vue';
import Dashboard from "../vue/Dashboard.vue";
import '../client/composition-api';


var dashboard = new Vue({
  components: {
    Dashboard,
  },
});

// dashboard.directive('resize', {
//   mounted: function (el, binding) {
//     const onResizeCallback = binding.value;
//     window.addEventListener('resize', () => {
//       const width = document.documentElement.clientWidth;
//       const height = document.documentElement.clientHeight;
//       onResizeCallback({ width, height });
//     })
//   }
// });

// dashboard.use(VueClipboard);
// dashboard.use(VueCompositionAPI);

const target = document.getElementById('dashboard');
if (target != undefined) {
  dashboard.$mount(target);
}