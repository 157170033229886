// import { DATA_DESCRIPTOR } from "../../../assets/plugins/jszip/jszip";


(function() {
  $(function() {
    var $this = $('input[type="datetime"]');
    if ($this.length > 0) {
      $this.each((__, element) => {
        // console.log($(element).datetimepicker());
      });
    }
  });
  var $table = $("#registered_users");
  if ($table.length > 0) {
    $table.DataTable({
      bJQueryUI: true, //Enable smooth theme
      responsive: true,
      sPaginationType: "full_numbers", //Enable smooth theme
      dom: "Bfrtip",
      buttons: ["copy", "csv", "excel", "pdf", "print"],
      drawCallback: function(settings) {
        settings._buttons[0].inst.dom.container.appendTo(
          $("#seminar_participants").find(".option-btn").width('228')
        );
      },
    });
  }

    // CKEDITOR.replace( 'editor1' );

})();
