<template>
  <div ref="chart1"></div>
</template>


<script>
import { ref } from "vue";
import Highcharts from "highcharts";

var categories = [
  "0-4",
  "5-9",
  "10-14",
  "15-19",
  "20-24",
  "25-29",
  "30-34",
  "35-39",
  "40-44",
  "45-49",
  "50-54",
  "55-59",
  "60-64",
  "65-69",
  "70-74",
  "75-79",
  "80-84",
  "85-89",
  "90-94",
  "95-99",
  "100 + ",
];

export default {
  mounted() {
    const config = {
      headers: {
        "X-CSRF-TOKEN": window._token,
      },
    };

    const request = axios.get("/api/ib/trade-density", config);
    request.then(({data}) => {

      console.log(data);
      var options = {
        chart: {
          type: "bar",
        },
        title: {
          text: "",
        },
        accessibility: {
          point: {
            valueDescriptionFormat: "{index}. Age {xDescription}, {value}%.",
          },
        },
        xAxis: [
          {
            categories: data.categories,
            reversed: false,
            labels: {
              step: 1,
            },
            accessibility: {
              description: "Age (male)",
            },
          },
          {
            // mirror axis on right side
            opposite: true,
            reversed: false,
            categories: data.categories,
            linkedTo: 0,
            labels: {
              step: 1,
            },
            accessibility: {
              description: "Age (female)",
            },
          },
        ],
        yAxis: {
          title: {
            text: null,
          },
          labels: {
            formatter: function () {
              return Math.abs(this.value) + "%";
            },
          },
          accessibility: {
            description: "Percentage population",
            rangeDescription: "Range: 0 to 5%",
          },
        },

        plotOptions: {
          series: {
            stacking: "normal",
          },
        },

        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.series.name +
              ", pair " +
              this.point.category +
              "</b><br/>" +
              Highcharts.numberFormat(Math.abs(this.point.y), 1) +
              "%"
            );
          },
        },

        series: [
          {
            name: "Buy",
            data: data.buys,
          },
          {
            name: "Sell",
            data: data.sells,
          },
        ],
      };
      Highcharts.chart(this.$refs.chart1, options);
    });
  },
  data() {
    return {};
  },
};
</script>