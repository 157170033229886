window.getAllStyles = function (elem) {
  if (!elem)
    return []; // Element does not exist, empty list.
  var win = document.defaultView || window,
    style,
    styleNode = [];
  if (win.getComputedStyle) {
    /* Modern browsers */
    style = win.getComputedStyle(elem, "");
    for (var i = 0; i < style.length; i++) {
      styleNode.push(style[i] + ":" + style.getPropertyValue(style[i]));
      //               ^name ^           ^ value ^
    }
  } else if (elem.currentStyle) {
    /* IE */
    style = elem.currentStyle;
    for (var name in style) {
      styleNode.push(name + ":" + style[name]);
    }
  } else {
    /* Ancient browser..*/
    style = elem.style;
    for (var i = 0; i < style.length; i++) {
      styleNode.push(style[i] + ":" + style[style[i]]);
    }
  }
  return styleNode;
};
