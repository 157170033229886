import Spruce from "@ryangjchandler/spruce";
// import tailDatetime from "tail.datetime";

window.withdrawal_request = function () {

  const elem = document.getElementById('range');
  const dateRangePicker = new DateRangePicker(elem, {
    container: ".relative",
    allowOneSidedRange: true,
    todayHighlight: true,
  });

  var withdrawal_history = Spruce.store("withdrawal_history", {
    loading: true,
    startDate: null,
    stopDate: null,
    selections: [],
  });

  var table = $("#withdrawalrequest-table").DataTable({
    serverSide: true,
    processing: true,
    ajax: {
      type: "GET",
      data: function (data) {
        for (var i = 0, len = data.columns.length; i < len; i++) {
          if (data.columns[i].data === "action") delete data.columns[i];
        }
        delete data.search.regex;
      },
    },
    columns: [{
        data: "checkbox",
        name: "checkbox",
        title: '<input type="checkbox" @click="selectAll()" :checked="checkedAll" class="appearance-none border border-gray-300 checked:bg-blue-600 checked:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0 h-5 ring-0 rounded-md w-5 p-0!">',
        orderable: false,
        searchable: false,
        className: "h-full p-1.5! sorting_disabled text-center text-gray-800 text-xs font-semibold  font-semibold uppercase",
        width: 10,
      },
      {
        data: "transaction_id",
        name: "transaction_id",
        title: "Transaction ID",
        orderable: true,
        searchable: true,
        // width: 100,
        className: "text-center text-xs font-semibold uppercase text-gray-800",
      },
      {
        data: "account_id",
        name: "account_id",
        title: "Account ID",
        orderable: true,
        searchable: true,
        // width: 100,
        className: "text-center text-xs font-semibold uppercase text-gray-800",
      },
      {
        data: "requested_amount",
        name: "requested_amount",
        title: "Amount",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold uppercase text-gray-800",
      },
      {
        data: "created_at",
        name: "created_at",
        title: "Date",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold uppercase text-gray-800",
      },
      {
        data: "updated_at",
        name: "updated_at",
        title: "Confirm Date",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold uppercase text-gray-800",
      },
      {
        data: "currency",
        name: "currency",
        title: "Currency",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold uppercase text-gray-800",
      },
      {
        data: "method",
        name: "method",
        title: "Method",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold uppercase text-gray-800",
      },
      {
        data: "status",
        name: "status",
        title: "Status",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold uppercase text-gray-800",
      },
      {
        data: "action",
        name: "action",
        title: "",
        orderable: false,
        searchable: false,
        className: "text-center text-xs font-semibold uppercase text-gray-800",
      },
    ],
    autoWidth: false,
    responsive: true,
    language: {
      emptyTable: "No Transaction history",
      paginate: {
        previous: '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon icon-tabler icon-tabler-chevrons-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n                                <polyline points="11 7 6 12 11 17" />\n                                <polyline points="17 7 12 12 17 17" />\n                            </svg>',
        next: '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon icon-tabler icon-tabler-chevrons-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n                                <polyline points="7 7 12 12 7 17" />\n                                <polyline points="13 7 18 12 13 17" />\n                            </svg>',
      },
    },
    dom: "rtip",
    order: [
      [1, "desc"]
    ],
    initComplete: function () {
      withdrawal_history.loading = false;
    },
    drawCallback: function (settings) {
      for (var i = 0; i < table.rows().count(); i++) {
        _this.actions.push({
          open: false,
        });
      }
      var data = table.ajax.json().data;
      data.forEach((elem) => {
        withdrawal_history.selections[elem.id] = {
          checked: false,
          user_id: elem.user_id,
          email: elem.email
        };
      });
    },
    buttons: [{
      extend: "export"
    }, {
      extend: "reload"
    }],
  });

  var _this = {
    loading: true,
    open: false,
    _token: "_token",
    buckMailing: false,
    loading: true,
    checkedAll: false,
    selected: 0,
    actions: [],
    selectAll: function () {
      this.checkedAll = !this.checkedAll;
      this.buckMailing = this.checkedAll;
      this.selected = table.ajax.json().data.length;
      $('input[type="checkbox"]').prop("checked", this.checkedAll);
      withdrawal_history.selections.forEach((__, index) => {
        withdrawal_history.selections[index].checked = _this.checkedAll;
      });
    },
    init: function (startDate, stopDate) {
      Spruce.store("withdrawal_history").startDate = startDate;
      Spruce.store("withdrawal_history").stopDate = stopDate;
    },
    search: function (event) {
      table.search(event.target.value).draw();
    },
    exportPDF: function () {
      table.buttons(".buttons-pdf").trigger();
    },

    reload: function () {
      table.ajax.reload();
    },
    confirm: function (id) {
      console.log(id);
    },
    decline: function (id) {
      sweetAlert({
        title: "Are you sure you want to decline this?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          _this.performDecline(id);
        }
      });
    },
    performDecline(id) {
      $.ajax({
        url: `${window.location.pathname}/${id}/decline`,
        method: "post",
        success: function () {
          console.log("is working");
          _this.reload();
        },
        error: function (err) {
          console.log(err);
        },
      });
    },
    sendBuckMail: function () {
      var ids = [];
      var emails = [];
      withdrawal_history.selections.forEach((elem) => {
        ids.push(elem.user_id);
        emails.push(elem.email);
      });

      emails = emails.filter((value, index) => emails.indexOf(value) === index);
      ids = ids.filter((value, index) => ids.indexOf(value) === index);
      mailer("id", ids, emails);
    },
  };

  return _this;
};
