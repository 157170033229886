window.promotion = function () {

    var _this = {
        currentBanner: "",
        showButtons: false,
        slide: 0,
        count: 0,
        loaded: false,
        init: function () {
            this.count = this.$refs.slidebox.getElementsByTagName('li').length;
            var next = this.$refs.next;
            this.loaded = true;

            setInterval(() => {
                $(next).trigger('click');
            }, 10000);
        },
        onNext: function () {
            if ((this.slide + 1) < this.count)
                this.slide++;
            else {
                this.slide = 0;
            }
        },
        onPrev: function () {
            if (this.slide > 0)
                this.slide--;
            else {
                this.slide = 0;
            }
        }
    };

    return _this;
}

window.robotSubscription = function () {

    return {
        open: false,
        loading: false,
        handle: function (detail) {
            this.open = true;
            console.log(detail);
        },
        onSubmit: function (event) {
            this.open = false;
            $.ajax({
                url: event.target.action,
                method: 'post',
                headers: {
                    'X-CSRF-TOKEN': window._token
                },
                success: (data) => {
                    this.loading = false;
                    this.open = false;
                    if (data.status === 1)
                        notify("success", data.message);
                },
                error: (data) => {
                    this.loading = false;
                    this.open = false;
                    const _data = data.responseJSON;
                    notify("error", _data.message);
                }
            });
        }
    }
}