<script>
import axios from "axios";
import { ref } from "vue";

export default {
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside.bind(this));
    axios
      .get("/api/ib/links", this.config)
      .then((response) => (this.links = response.data))
      .catch(() => {});
  },
  data() {
    return {
      show: false,
      links: [],
      config: {
        headers: {
          "X-CSRF-TOKEN": window._token,
        },
      },
    };
  },
  methods: {
    handleClickOutside({ target }) {
      if (
        this.$refs.dropdown &&
        this.$refs.button &&
        !this.$refs.dropdown.contains(target) &&
        !this.$refs.button.contains(target)
      ) {
        this.show = false;
      }
    },
    onShowLinks() {
      this.show = !this.show;
    },
    doCopy: function (link) {
      this.$copyText(link).then(
        function (e) {
          notify("success", {
            title: "Link Copied",
            message: "Copied to clipboard",
          });
        },
        function (e) {
          console.log(e);
        }
      );
      this.show = false;
    },
    onWithdrawClick: function () {
      axios
        .post("/api/ib/withdrawal", this.config)
        .then((response) => {
          const { data } = response;
          notify("success", data);
        })
        .catch((error) => {
          const {
            response: { data },
          } = error;
          notify("error", data);
        });
    },
  },
};
</script>

<template>
  <div>
    <div class="flex justify-end w-full">
      <div>
        <div>
          <label id="listbox-label" class="sr-only">
            Change published status
          </label>
          <div class="relative">
            <div class="inline-flex divide-x divide-indigo-600">
              <div class="relative z-0 inline-flex">
                <div class="relative inline-flex items-center py-2 pl-3 pr-4">
                  <p class="ml-2.5 text-sm font-medium"></p>
                </div>
                <button
                  type="button"
                  aria-label="withdrawal"
                  @click="onWithdrawClick"
                  class="
                    px-4
                    py-1
                    -mr-px
                    text-sm
                    font-semibold
                    transition-all
                    duration-300
                    ease-in-out
                    border
                    rounded-md rounded-r-none
                    focus:outline-none
                    hover:bg-gray-50
                  "
                >
                  Transfer to wallet
                </button>
                <button
                  ref="button"
                  @click.prevent="onShowLinks"
                  type="button"
                  class="
                    px-4
                    py-1
                    text-sm
                    font-semibold
                    transition-all
                    duration-300
                    ease-in-out
                    border
                    rounded-md rounded-l-none
                    focus:outline-none
                    hover:bg-gray-50
                  "
                  aria-haspopup="listbox"
                  aria-expanded="true"
                  aria-labelledby="listbox-label"
                >
                  <span class="sr-only">Sign Up Link</span>
                  Sign Up Links
                </button>
              </div>
            </div>

            <ul
              class="
                absolute
                right-0
                z-10
                mt-2
                overflow-hidden
                origin-top-right
                bg-white
                divide-y divide-gray-200
                rounded-md
                shadow-lg
                w-72
                ring-1 ring-black ring-opacity-5
                focus:outline-none
              "
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-0"
              :class="show ? 'visible' : 'invisible'"
              ref="dropdown"
            >
              <li
                class="
                  relative
                  py-1.5
                  px-4
                  text-sm text-gray-900
                  cursor-default
                  select-none
                  hover:bg-blue-500 hover:text-white
                  group
                "
                role="option"
                v-for="(item, index) in links"
                :key="index"
                ref="copyItem"
              >
                <div class="flex flex-col group-hover:text-white">
                  <div class="flex justify-between">
                    <p class="font-semibold" v-text="item.type"></p>
                  </div>
                  <p class="mt-2 text-sm" v-text="item.link"></p>
                  <a
                    href="javascript:void(0)"
                    class="absolute inset-0"
                    @click="doCopy(item.link)"
                  ></a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
