window.initializeAccountsTable = () => {
  $.ajaxSetup({
    headers: {
      "X-CSRF-TOKEN": $("csrf-token").attr("content"),
    },
  });
  toastr.clear();
  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: "toast-top-center",
    preventDuplicates: true,
    showDuration: "1000",
    hideDuration: "10000",
    extendedTimeOut: "1000",
    timeOut: 3000,
  };

  var accountsTable = $("#accounts-table").DataTable({
    serverSide: true,
    processing: true,
    ajax: {
      type: "GET",
      data: function (data) {
        // for (var i = 0, len = data.columns.length; i < len; i++) {
        //   if (!data.columns[i].search.value) delete data.columns[i].search;
        //   if (data.columns[i].searchable === true)
        //     delete data.columns[i].searchable;
        //   if (data.columns[i].orderable === true)
        //     delete data.columns[i].orderable;
        //   if (data.columns[i].data === data.columns[i].name)
        //     delete data.columns[i].name;
        // }
        // delete data.search.regex;
      },
    },
    columns: [
      {
        data: "name",
        name: "name",
        title: "Name",
        orderable: true,
        searchable: true,
        className: "text-center text-xs uppercase text-gray-700",
      },
      {
        data: "description",
        name: "description",
        title: "Description",
        orderable: true,
        searchable: true,
        className: "text-center text-xs uppercase text-gray-700",
        width: 100,
      },
      {
        data: "type",
        name: "type",
        title: "Type",
        orderable: true,
        searchable: true,
        width: 10,
        className: "text-center text-xs uppercase text-gray-700",
      },
      {
        data: "initial_amount",
        name: "initial_amount",
        title: "Int. Amount",
        orderable: true,
        searchable: true,
        width: 50,
        className: "text-center text-xs uppercase text-gray-700",
      },
      {
        data: "minimum_deposit",
        name: "minimum_deposit",
        title: "Min. Deposit",
        orderable: true,
        searchable: true,
        width: 50,
        className: "text-center text-xs uppercase text-gray-700",
      },
      {
        data: "leverages",
        name: "leverages",
        title: "Leverages",
        orderable: true,
        searchable: true,
        className: "text-center text-xs uppercase text-gray-700",
      },
      {
        data: "currencies",
        name: "currencies",
        title: "Cur.",
        orderable: true,
        searchable: true,
        className: "text-center text-xs uppercase text-gray-700",
        tooltip: "Currency",
      },
      {
        data: "platforms",
        name: "platforms",
        title: "Platforms",
        orderable: true,
        searchable: true,
        className: "text-center text-xs uppercase text-gray-700",
      },
      {
        data: "created_at",
        name: "created_at",
        title: "Date",
        orderable: true,
        searchable: true,
        className: "text-center text-xs uppercase text-gray-700",
      },
      {
        data: "action",
        name: "action",
        title: "",
        orderable: false,
        searchable: false,
        width: 10,
        className: "text-center text-xs uppercase text-gray-700",
      },
    ],
    responsive: true,
    autoWidth: false,
    dom: "rtip",
    order: [[1, "desc"]],
    buttons: [
      { extend: "create", className: "bg-primary p-1 text-xs h-5" },
      { extend: "reset", className: "p-1 text-xs h-5" },
      { extend: "reload", className: "p-1 text-xs h-5" },
    ],
  });

  return {
    init: function () {
      var self = this;
      window.onload = function () {
        self.loading = false;
      };
    },
    loading: true,
    search: function(event){
      accountsTable.search(event.target.value, true, true, true).draw();
    },
    create: function(){
      accountsTable.buttons('.buttons-create').trigger()
    },
    reload: function(){
      accountsTable.buttons('.buttons-reload').trigger()
    },
    __delete: function (ref) {
      var $self = this;
      sweetAlert({
        title: "Are you sure you want to delete this?",
        text: "You can't reverse this action when done.",
        icon: "warning",
        dangerMode: true,
        buttons: true,
        timer: 3000,
      }).then((willDelete) => {
        if (willDelete) {
          $.ajax({
            method: "delete",
            url: ref.href,
            success: function (res) {
              toastr.success("Successfully deleted");
              setTimeout($self.table().ajax.reload, 1000);
            },
            error: function () {
              toastr.error("Can't delete this row");
            },
          });
        }
      });
    },
  };
};

window.initAccounts = () => {
  return {
    count: 0,
    type: "",
    loaded: true,
    real: false,
    createAccount: async function ($form) {
      $.ajaxSetup({
        headers: {
          "X-CSRF-TOKEN": $("csrf-token").attr("content"),
        },
      });

      toastr.clear();
      toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: "toast-top-center",
        preventDuplicates: true,
        showDuration: "1000",
        hideDuration: "10000",
        extendedTimeOut: "1000",
        timeOut: 3000,
      };

      var $platforms = $("#account_platforms");
      if ($platforms.length > 0) {
        $.get("/admin/accounts/platforms").then((value) => {
          if (typeof value === "object") {
            var options = [];
            var leverages = [];
            var currencies = [];

            value.forEach((element) => {
              options.push({
                value: element.platforms.id,
                text: element.platforms.platform_name,
              });

              currencies = currencies.concat(
                element.platforms.currency.split(",")
              );

              var entries = Object.entries(element.leverages);
              entries.forEach((value) => {
                leverages.push({ text: value[1], value: value[1] });
              });
            });
            var __currencies = [...new Set(currencies)];
            currencies.length = 0; //clear
            __currencies.forEach((value) => {
              currencies.push({ value: value, text: value });
            });

            $platforms.selectize({
              plugins: ["remove_button"],
              delimiter: ",",
              persist: false,
              labelField: "text",
              valueField: "value",
              options: options,
            });

            var $type = $("#account_leverages");
            if ($type.length > 0) {
              $type.selectize({
                plugins: ["remove_button"],
                delimiter: ",",
                persist: false,
                labelField: "text",
                valueField: "value",
                options: leverages,
                maxItems: 7,
              });
            }

            var $currencies = $("#account_currencies");
            if ($currencies.length > 0) {
              $currencies.selectize({
                plugins: ["remove_button"],
                delimiter: ",",
                persist: false,
                labelField: "text",
                valueField: "value",
                options: currencies,
                maxItems: 5,
              });
            }
          }
        });
      }
    },
    create: function ($form) {
      $.ajax({
        url: $form.action,
        method: $form.method,
        data: $($form).serialize(),
        success: function (res) {
          toastr.success("Successfully Added!!");
        },
        error: function (res) {
          toastr.error(
            "Something happened, can't create. check internet connection",
            "Error"
          );
        },
      });
    },
    update: function ($form) {
      $.ajax({
        url: $form.action,
        method: "PUT",
        data: $($form).serialize(),
        success: function (res) {
          toastr.success("Updated Successful!");
        },
        error: function (res) {
          toastr.error(
            "Something happened, can't save. check internet connection",
            "Error"
          );
        },
      });
    },
  };
};
