import AutoNumeric from "autonumeric";
import { isUndefined } from "lodash";

export default class FundAccount {
    constructor() {
        this.amount = null;
        this.account = '';
        this.id = '';
        this.balance = 0.0;
    }

    initialize() {
        Spruce.store('fundAccount', {
            open: false,
            disable: false,
        });

        window.addEventListener('fund-account', ({ detail }) => this.show(detail));
    }

    show(detail) {
        this.id = detail.selected;
        this.balance = detail.balance;
        this.$store.fundAccount.open = true;
        var amount = this.$refs.amount;
        // if (AutoNumeric.getAutoNumericElement(amount) == null) {
        //     this.amount = new AutoNumeric(amount, {
        //         currencySymbol: amount.getAttribute('data-symbol'),
        //     });
        // }
    }

    submit() {
        var form = this.$refs.form;
        // var amount = this.amount.getNumber();
        var amount = this.$refs.amount.value;
        var account = this.id;
        var payload = { account: account, amount: amount };

        var $store = this.$store;
        $store.fundAccount.disable = true;
        axios.post(`${form.action}/${account}`, payload, {
            withCredential: true, headers: {
                "Context-Type": "application/json"
            }
        })
            .then(({ data }) => {
                $store.fundAccount.disable = false;
                notify('success', data);
                if (!isUndefined(application.stats)) {
                    application.stats.updateFundStatus(payload);
                }
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch(() => {
                notify('error', {
                    message: "Fund account failed", title: "Fund Account"
                });
                dispatch('funderror', payload);
                $store.fundAccount.disable = false;
            });
    }

    onChange() {
        this.$store.fundAccount.disable = !this.amount.getNumber() > 0;
        if (this.$refs.__amount) this.$refs.__amount.value = this.amount.getNumber();
    }
}
