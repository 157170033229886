<template>
  <div class="bg-white rounded-lg shadow">
    <h2 class="sr-only">Profile Overview</h2>
    <div class="p-6 bg-white">
      <div class="sm:flex sm:items-center sm:justify-between">
        <div class="flex flex-col justify-center">
          <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
            <p class="text-sm font-medium text-gray-600">Balance</p>
            <p class="text-xl font-bold text-gray-900 sm:text-2xl" v-text="balance"></p>
          </div>
          <div class="mt-8 text-center sm:mt-0 sm:pt-1 sm:text-left">
            <p class="text-sm font-medium text-gray-600">Partner ID</p>
            <p class="text-xl font-bold text-gray-900 sm:text-lg" v-text="id"></p>
          </div>
          <div class="flex flex-row mt-8 text-center sm:mt-0 sm:pt-1 sm:text-left">
            <p class="text-sm font-medium text-gray-600 font-semibold">Last Withdrawal:</p>
            <p class="text-sm text-gray-900 ml-2 font-medium" v-text="last_withdrawal"></p>
          </div>
        </div>
        <div class="flex justify-center mt-5 sm:mt-0">
          <LinkManager />
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 border-t border-gray-200 divide-y divide-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
      <div class="px-6 py-5 text-sm font-medium text-center">
        <span class="text-gray-900">All Clients</span>
        <span class="ml-2 text-gray-600" v-text="clients"></span>
      </div>

      <div class="px-6 py-5 text-sm font-medium text-center">
        <span class="text-gray-900">Lots</span>
        <span class="ml-2 text-gray-600" v-text="volumes"></span>
      </div>

      <div class="px-6 py-5 text-sm font-medium text-center">
        <span class="text-gray-900">Paid Commission</span>
        <span class="ml-2 text-gray-600" v-text="commission"></span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LinkManager from "./LinkManager.vue";

export default {
  components: {
    LinkManager,
  },
  mounted() {
    const config = {
      headers: {
        "X-CSRF-TOKEN": window._token,
      },
    };

    const request = axios.get("/api/ib/user-status", config);
    request.then((response) => {
      const { data } = response;
      this.clients = data.clients;
      this.id = data.id;
      this.balance = data.balance;
      this.volumes = data.volumes;
      this.commission = data.commission;
      this.last_withdrawal = data.last_withdrawal
    });
  },
  data() {
    return {
      clients: 0.0,
      balance: 0.0,
      id: "",
      volumes: "",
      commission: 0.0,
      last_withdrawal: ""
    };
  },
};
</script>