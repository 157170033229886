// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from 'moment';
import 'daterangepicker';

(function(){

    var __editor = null;
    var $description = $("#wcontent");
    if ($description.length > 0) {
      CKEDITOR.replace(  $description[0] );
      // ClassicEditor.create($description[0]).then((editor) => {
      //   __editor = editor;
      // });
    }
    var $this=$('input[type="date-range"]');
    if($this.length>0){
        $this.each((__, element)=>{
            $(element).daterangepicker(
                {
                    timePicker: true,
                    startDate: moment().startOf('hour'),
                    endDate: moment().startOf('hour').add(32, 'hour'),
                    locale: {
                      format: 'DD/M/YYYY hh:mm A'
                    }
                }
            );
        });
    }
})();