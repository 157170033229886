import Spruce from "@ryangjchandler/spruce";
import validator from "validator";
import "moment-timer";
import moment from "moment";

window.handleWithdrawal = function (parent) {
  var withdrawal = Spruce.store("withdrawal", {
    conversion: 0.0,
    converting: false,
    enable_2fa: false,
    auth_type: "2FA Token",
    auth2: "auth2",
    counter: "",
    verification_sent: false,
    canResend: false,
  });

  var _this = {
    open: false,
    logo: "",
    alt: "",
    name: "",
    show_confirmation: false,
    execution: "",
    amount: 0.0,
    balanceId: "balance",
    maxAmount: 0.0,
    minAmount: 0.0,
    symbol: "",
    alert: false,
    message: "",
    expected: 0.0,
    processing: false,
    sec_count: 0,
    max_retry_time: null,
    timer: null,
    token: "",
    _select_currency_id: "currency_id",
    default_currency: "default",
    currency: "",
    conversion: 0.0,
    id: 0,
    method_id: 0,
    _token: "_token",
    show: function (detail) {
      this.editData();
      this.open = true;
      this.processing = false;
      this.logo = detail.logo;
      this.alt = detail.name;
      this.name = detail.name;
      this.execution = detail.execution;
      this.maxAmount = parseFloat(detail.maxAmount);
      this.minAmount = parseFloat(detail.minAmount);
      this.method_id = detail.id;
      this.token = $(`#${_this._token}`).val();
      $.ajax({
        headers: {
          "X-CSRF-TOKEN": this.token,
        },
        async: false,
        success: _this.init,
        error: function () {
          notify("error", {
            title: "Initialization Failure",
            message:
              "Can't initialize withdrawal modal, please check network and reload",
          });
        },
      });
    },
    init: function (result) {
      withdrawal.enable_2fa = result.enable_2fa;
      _this.max_retry_time = result.max_retry_time;
      _this.sec_count = new moment.duration(
        _this.max_retry_time,
        "minutes"
      ).asSeconds();
      var mins = moment.duration(_this.sec_count, "seconds").minutes();
      var secs = moment.duration(_this.sec_count, "seconds").seconds();
      withdrawal.counter = `${mins} min, ${secs}`;

      if (!withdrawal.enable_2fa) {
        withdrawal.auth2 = "auth_token";
        withdrawal.auth_type = "Confirmation Token";
      }
    },
    requestWithdrawal: function (res) {
      _this.show_confirmation = true;
      withdrawal.verification_sent = true;
      _this.resendVerificationCode();
      console.log(res);
    },
    requestWithdrawalError: function (err) {
      console.log(err);
    },
    requestConfirmation: function () {
      var action = String(parent.action);
      var data = [];
      data.push({
        name: "w",
        value: "verify",
      });
      $.ajax({
        url: action,
        data: $.param(data),
        headers: {
          "X-CSRF-TOKEN": this.token,
        },
        success: _this.requestWithdrawal,
        error: _this.requestWithdrawalError,
      });
    },
    performRequest: function (result) {
      _this.restartTimer();
      _this.processing = true;
      if (result.status === 1) {
        _this.processing = false;
        _this.open = false;
        notify("success", {
          title: result.title,
          message: result.message,
        });
      }
    },
    error: function ({ responseJSON: err }) {
      _this.processing = false;
      if (err) {
        notify("error", {
          title: err.title,
          message: err.message,
        });
      } else {
        notify("error", {
          title: "Common Error",
          message:
            "Please check your internet connection or clear cookies and try again",
        });
      }
    },
    computeRate: function (res) {
      withdrawal.conversion = res && res.price;
      withdrawal.converting = false;
    },
    handlerAmount: function () {
      if (!validator.isDecimal(String(this.amount))) this.amount = "";
      var sc = document.getElementById(_this._select_currency_id);
      var currency = sc && sc.options[sc.options.selectedIndex].innerText;
      var default_currency = document.getElementById(_this.default_currency)
        .innerText;
      var text = document.getElementById(this.balanceId).innerText;
      var balance = parseFloat(text.substring(1));
      if (this.minAmount != NaN && this.amount <= this.minAmount) {
        this.alert = true;
        this.message = "The minimum amount is ";
        this.expected = this.minAmount;
      } else if (this.maxAmount != NaN && this.amount >= this.maxAmount) {
        this.alert = true;
        this.message = "The maximum amount is ";
        this.expected = this.maxAmount;
      } else if (this.amount > balance) {
        this.alert = true;
        this.message = "Amount exceed balance ";
        this.expected = balance;
      } else {
        this.alert = false;
        this.message = "";
        this.expected = "";
      }

      var rate = [];
      rate.push({ name: "amount", value: this.amount });
      rate.push({ name: "from", value: currency });
      rate.push({ name: "to", value: default_currency });
      Spruce.store("withdrawal").converting = true;
      $.ajax({
        url: "exchange/rate",
        method: "post",
        data: $.param(rate),
        success: _this.computeRate,
        error: function (err) {},
      });
    },
    request: function () {
      var formData = $(parent).serializeArray();
      var sc = document.getElementById(_this._select_currency_id);
      var currency = sc && sc.options[sc.options.selectedIndex].innerText;
      formData.push({ name: "currency", value: currency });
      formData.push({ name: "amount", value: withdrawal.conversion });

      if (withdrawal.conversion <= 0) {
        notify("error", {
          title: "Withdrawal Error",
          message: "Please specify a value above 0",
        });
        return;
      }

      var action = String(parent.action);
      $.ajax({
        url: action.replace("#", this.id),
        method: "POST",
        data: $.param(formData),
        success: _this.performRequest,
        error: _this.error,
      });
    },
    startCountdown: function () {
      var mins = moment.duration(_this.sec_count, "seconds").minutes();
      var secs = moment.duration(_this.sec_count, "seconds").seconds();
      withdrawal.counter = `${mins} min, ${secs}`;
      _this.sec_count--;
      if (_this.sec_count <= 0) {
        _this.timer.stop();
        withdrawal.canResend = true;
      }
    },
    editData: function () {
      this.show_confirmation = false;
      withdrawal.verification_sent = false;
      this.processing = false;
      this.sec_count = this.max_retry_time;
      if (_this.timer) {
        _this.timer.stop();
        withdrawal.canResend = true;
      }
    },
    restartTimer: function () {
      if (_this.timer == null) {
        _this.timer = new moment.duration(1, "second").timer(
          { loop: true },
          _this.startCountdown
        );
      }
      _this.timer.start();
    },
    resendVerificationCode: function () {
      var action = String(parent.action);
      $.ajax({
        url: action.replace("#", ""),
        data: $.param([{ name: "verification", value: "resend" }]),
        headers: {
          "X-CSRF-TOKEN": this.token,
        },
        success: function () {
          withdrawal.verification_sent = true;
          withdrawal.canResend = false;
          _this.sec_count = moment
            .duration(_this.max_retry_time, "minutes")
            .asSeconds();
          _this.restartTimer();
        },
        error: function (err) {
          notify("error", {
            title: "Network Error",
            message: "Can't resend verification code, please check network",
          });
        },
      });
    },
  };

  return _this;
};
