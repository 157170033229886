import Spruce from "@ryangjchandler/spruce";
import tailDatetime from "tail.datetime";

window.internalTransfer = function (startDate, stopDate) {
  Spruce.store("balance_table", {
    startDate: startDate,
    stopDate: stopDate,
    search: "",
    idle: true,
  });

  var table = $("#transaction-history-table").DataTable({
    serverSide: true,
    processing: true,
    ajax: {
      type: "GET",
      // data: function (data) {
      // },
    },
    columns: [
      {
        data: "transaction_id",
        name: "transaction_id",
        title: "Transfer ID",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "destination_id",
        name: "destination_id",
        title: "Destination ID",
        orderable: true,
        searchable: true,
        // width: '100px',
        className: "text-center text-xs font-semibold",
      },
      {
        data: "source_id",
        name: "source_id",
        title: "Source ID",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "requested_amount",
        name: "requested_amount",
        title: "Amount",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "created_at", //send_at
        name: "created_at",
        title: "Send At",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "currency",
        name: "currency",
        title: "Currency",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
        width: '100px',
      },
      {
        data: "status",
        name: "status",
        title: "Status",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
    ],
    autoWidth: false,
    responsive: true,
    language: {
      emptyTable: "No Transaction history",
      paginate: {
        previous:
          '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon icon-tabler icon-tabler-chevrons-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n                                <polyline points="11 7 6 12 11 17" />\n                                <polyline points="17 7 12 12 17 17" />\n                            </svg>',
        next:
          '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon icon-tabler icon-tabler-chevrons-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n                                <polyline points="7 7 12 12 7 17" />\n                                <polyline points="13 7 18 12 13 17" />\n                            </svg>',
      },
    },
    dom: "rtip",
    order: [[1, "desc"]],
    buttons: [
      {
        extend: "reload",
      },
      {
        name: 'pdfHtml5',
        extend: 'pdfHtml5',
        exportOptions: {
          columns: function (idx, data, node) {
            var $column = table.column(idx);
            return ($column.visible() && $(node).attr('data-visible-in-export') != 'false') || $(node).attr('data-force-export') == 'true';
          }
        },
        orientation: 'landscape',
        action: function (e, dt, button, config) {
          $.fn.DataTable.ext.buttons.pdfHtml5.action.call(this, e, dt, button, config);
        }
      },
      {
        extend: "excel",
      },
    ],
    initComplete: function () {
      Spruce.store("balance_table").idle = false;
      Spruce.watch("balance_table.search", (value) => {
        table.search(value, true, true, true).draw();
      });
    },
  });

  $.fn.DataTable.ext.buttons.reload = {
    className: 'buttons-reload',

    text: function (dt) {
      return '<i class="fa fa-refresh"></i> ' + dt.i18n('buttons.reload', 'Reload');
    },

    action: function (e, dt, button, config) {
      dt.draw(false);
    }
  };

  var rangeStart = tailDatetime("#start", {
    classNames: false,
    closeButton: false,
    rtl: "auto",
    animate: false,
    today: true,
    dateFormat: "dd-mm-YYYY",
    timeFormat: null,
  });

  var rangeStop = tailDatetime("#stop", {
    classNames: false,
    closeButton: false,
    rtl: "auto",
    animate: false,
    today: true,
    dateFormat: "dd-mm-YYYY",
    timeFormat: null,
  });

  rangeStart.on("change", function () {
    Spruce.store("balance_table").startDate = this.convertDate(
      this.fetchDate(),
      "dd-mm-YYYY"
    );
    rangeStart.dateStart = Spruce.store("balance_table").startDate;
  });

  rangeStop.on("change", function () {
    Spruce.store("balance_table").stopDate = this.convertDate(
      this.fetchDate(),
      "dd-mm-YYYY"
    );
  });

  return {
    options: false,
    exportPDF: function () {
      this.triggerThis("pdf");
    },
    reload: function () {
      table.ajax.reload();
    },
    exportExcel: function () {
      this.triggerThis("excel");
    },
    triggerThis: function (name) {
      table.buttons(`.buttons-${name}`).trigger();
    },
  };
};
