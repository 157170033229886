window.translationSwitcher = function () {
    return {
        selected: 0,
        translations: {
            // en: "Last Friday I saw a spotted striped blue worm shake hands with a legless lizard.",
        },
        countries: [
            {
                label: 'English',
                lang: 'en',
                flag: 'gb',
            },
        ],
        menuToggle: false,
    }
}