const Spruce = require('@ryangjchandler/spruce');

import 'lodash';
window.accountItem = function () {
  $.ajaxSetup({
    headers: {
      "X-CSRF-TOKEN": $('[name="csrf-token"]').attr("content"),
    },
  });

  var _this = {
    open: false,
    leverages: [],
    initialize: function () {
      var $account = Spruce.store(`_${this.$refs.item.id}`, {
        balance: '0.0',
        profit: '0.0',
        leverage: '',
      });
      $account.balance = this.$refs.balance.innerText;
      $account.profit = this.$refs.profit.innerText;
      $account.leverage = this.$refs.leverage.innerText;
    },
    onDelete: function (href, object) {
      $.ajax({
        method: "delete",
        url: href,
        success: function () {
          object.remove();
          notify("success", {
            title: "Delete Account",
            message: "Successful",
          });
        },
        error: function () {
          notify("error", {
            title: "Error Archiving",
            message: "Can't archive something happened",
          });
        },
      });
    },
    archive: function (href, object) {
      $.ajax({
        method: "delete",
        url: href,
        success: function () {
          object.remove();
          notify("success", {
            title: "Archived",
            message: "Account archived",
          });
        },
        error: function () {
          notify("error", {
            title: "Error Archiving",
            message: "Can't archive something happened",
          });
        },
      });
    },
    onRestore: function (href, object) {
      $.ajax({
        method: "post",
        url: href,
        success: function () {
          object.remove();
          notify("success", {
            title: "Restore account",
            message: "Account Restored",
          });
        },
        error: function () {
          notify("error", {
            title: "Restore Error",
            message: "Can't restore account something happened, try again",
          });
        },
      });
    },
    update: function (url) {
      var id = this.$refs.item.id;
      $.ajax({
        url: url,
        success: function (result) {
          var store = Spruce.store(`_${id}`);

          store.balance = Number(result.balance).toFixed(2).toString();
          store.profit = Number(result.profit).toFixed(2).toString();
          store.leverage = `1:${result.leverage}`;
          dispatch(`inactive-${id}`);
        }
      })
    }
  };

  _.bindAll(_this);

  return _this;
};

window.tradingAccountItem = function (login) {
  $.ajaxSetup({
    headers: {
      "X-CSRF-TOKEN": $('[name="csrf-token"]').attr("content"),
    },
  });

  var _this = {
    open: false,
    leverages: [],
    onDelete: function (href) {
      $.ajax({
        method: "delete",
        url: href,
        success: function (result) {
          $(`#account_${login}`).remove();
          notify("success", result);
        },
        error: function (result) {
          notify("error", result);
        },
      });
    },
    archive: function (href) {
      $.ajax({
        method: "delete",
        url: href,
        success: function (result) {
          notify("success", result);
          $(`#account_${login}`).remove();
        },
        error: function (result) {
          notify("error", result);
          notify("error",);
        },
      });
    },
    inactive: function () {
      // location.reload();
    }
  };

  _.bindAll(_this);

  return _this;
};