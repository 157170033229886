window.cryptoWithdrawal = function () {
    Spruce.store('crypto', {
        loading: false,
    });

    return {
        loading: false,
        submit: function ($event) {
            var target = $event.target;
            this.$store.crypto.loading = true;
            
            if (target) {
                $.ajax({
                    url: target.action,
                    data: $(target).serialize(),
                    async: false,
                    method: 'post',
                    headers: {
                        'X-CSRF-TOKEN': _token
                    },
                    success: function (result) {
                        if (result && result.title) {
                            notify('success', result);
                            location.reload();
                        }
                    },
                    error: function (result) {
                        notify('error', result.responseJSON);
                    }
                });
                $store.crypto.loading = false;
            }

        }
    }
}