window.notificationHandler = function () {
    toastr.clear();
    toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: "toast-top-center",
        preventDuplicates: true,
        showDuration: "1000",
        hideDuration: "10000",
        timeOut: "2000",
        extendedTimeOut: "1000",
    };

    var _this = {
        init: function (detail) {
            this.handler(detail);
        },
        handler: function (detail) {
            toastr[detail.type](detail.data);
        }
    }

    return _this;
}

window.showNotification = function (type, data) {
    window.dispatchEvent(
        new CustomEvent("common-notification", { detail: { type: type, data: data } })
    );
}