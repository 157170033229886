<template>
  <div class="fixed inset-0 z-40 max-w-2xl m-auto h-4/6" v-show="show">
    <div>
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-50"></div>
      </div>
      <div
        class="
          flex flex-col
          max-w-md
          px-2
          mx-auto
          transform
          bg-white
          rounded-lg
        "
      >
        <div class="flex justify-end py-3">
          <button @click="onCancel">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <g fill="none">
                <path
                  d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5z"
                  fill="currentColor"
                />
              </g>
            </svg>
          </button>
        </div>
        <div
          class="overflow-x-hidden overflow-y-auto shadow-inner h-80"
          v-html="terms"
        ></div>
        <p class="mt-4 text-xs font-semibold">
          By clicking 'Enroll now' below I acknowledge that I have read, fully
          understand, accept and give my consent to all the terms and conditions
          as clearly stated above and on the website www.stereomafx.com subject to
          be amended from time to time.
        </p>
        <div
          class="
            flex
            items-center
            justify-center
            w-9/12
            gap-5
            py-2
            mx-auto
            mt-4
          "
        >
          <button
            class="py-1 bg-blue-600 rounded-md px-9 text-gray-50"
            @click="onEnroll"
          >
            Enroll now
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  methods: {
    onCancel: function () {
      window.location.href = "/user/dashboard";
    },
    onEnroll: function () {
      axios
        .post("/api/ib/enroll-now", null, this.config)
        .then((response) => {
          const { data } = response;
          if (data) this.show = false;
        })
        .catch((error) => {
          notify("error", {
            title: "Partnership Enrollment",
            message: "Can't enroll, please try again.",
          });
        });
    },
  },
  beforeMount() {
    axios.get("/api/ib/user-status", this.config).then((response) => {
      const { data } = response;
      if (data.has_enrolled) this.show = false;
      else {
        this.show = true;
        this.terms = data.terms;
        console.log(data.terms);
        // window.history.back();
      }
    });
  },
  data() {
    return {
      show: false,
      terms: "",
      config: {
        headers: {
          "X-CSRF-TOKEN": window._token,
        },
      },
    };
  },
};
</script>

<style>
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}

.c5 {
  padding-top: 0pt;
  padding-bottom: 10pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c0 {
  padding-top: 6pt;
  padding-bottom: 2pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c8 {
  padding-top: 10pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c10 {
  background-color: #ffffff;
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
}
.c13 {
  background-color: #ffffff;
  max-width: 451.4pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c7 {
  font-size: 12pt;
  font-weight: 600;
}
.c14 {
  background-color: #ffffff;
  color: #4e4e4e;
}
.c9 {
  color: inherit;
  text-decoration: inherit;
}
.c6 {
  font-size: 14pt;
  font-weight: 700;
}
.c4 {
  height: 11pt;
}
.c12 {
  font-size: 10pt;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
