window.blockAccount = function () {
  var blockAccount = Spruce.store("block_account", {
    open: false
  });
  
  toastr.clear();
  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: "toast-top-center",
    preventDuplicates: true,
    showDuration: "1000",
    hideDuration: "10000",
    timeOut: "2000",
    extendedTimeOut: "1000",
  };


  var _this = {
    target: null,
    type: "Block",
    onBlock: function () {
      if (this.target) {
        $.ajax({
          url: this.target,
          method: 'POST',
          headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
          },
          success: function (response) {
            blockAccount.open = false;
            toastr.success(response.message);
            
            window.dispatchEvent(
              new CustomEvent("account-blocked", { detail: { href: this.target } })
            );
          },
          error: function (error) {
            toastr.error('Unable to block account, please check internet connection');
          }
        });
      }
    },
    onClose: function () {
      blockAccount.open = false;
    },
    onHandle: function (detail) {
      blockAccount.open = true;
      this.target = detail.href;
      this.type = detail.type;
    }
  }

  return _this;
};


window.blockUserModal = function (href, type) {
  window.dispatchEvent(
    new CustomEvent("block-user", { detail: { href: href, type: type} })
  );
};
