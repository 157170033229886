const toastr = require("toastr");

window.platformSettings = function () {
  toastr.clear();
  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: "toast-top-center",
    preventDuplicates: true,
    showDuration: "1000",
    hideDuration: "10000",
    timeOut: "2000",
    extendedTimeOut: "1000",
  };

  $.ajaxSetup({
    headers: {
      "X-CSRF-TOKEN": $('[name="csrf-token"]').attr("content"),
    },
  });

  var $inputLeverages = $("#input-leverages");
  var _leverage = null;
  if ($inputLeverages.length > 0) {
    $inputLeverages.selectize({
      plugins: ["remove_button"],
      delimiter: ",",
      persist: false,
      onInitialize: function () {
        _leverage = this;
      },
      createFilter: function (input) {
        var match;
        match = input.match(/1:[0-9]+/gm);
        if (match) return true;
        return false;
      },
      create: function (input) {
        return {
          value: input,
          text: input,
        };
      },
    });
  }

  var $currency = $("#input-currency");
  var currency = null;
  if ($currency.length > 0) {
    $currency.selectize({
      onInitialize: function () {
        currency = this;
      },
      plugins: ["remove_button"],
      delimiter: ",",
      persist: false,
      maxOptions: 1,
      placeholder: "Entery Currency",
      create: function (input) {
        return {
          value: input,
          text: input,
        };
      },
    });
  }

  var $platform = $("#platform");
  var selectize_platform = null;
  if ($platform.length > 0) {
    $platform.selectize({
      onInitialize: function () {
        selectize_platform = this;
      },
      placeholder: "Select trading platform",
      create: true,
      sortField: "text",
      isRequired: true,
      onChange: function (input) {
        $.ajax({
          url: "trading-settings/" + this.getItem(input).text(),
          success: function (res) {
            _leverage.clear();
            var data = JSON.parse(res);
            if (data) {
              data.leverages.forEach((element) => {
                _leverage.addOption({
                  text: element.value,
                  value: element.value,
                });
                _leverage.addItem(element.value);
                _leverage.refreshItems();
              });

              currency.clear();
              currency.addOption({
                text: data.currency,
                value: data.currency,
              });
              currency.addItem(data.currency);
              currency.refreshItems();

              var $platformId = $("#platform-id");
              if ($platformId.length > 0) {
                $platformId.val(data.platform_id);
              }

              var $platformId = $("#platform-server");
              if ($platformId.length > 0) {
                $platformId.val(data.platform_server);
              }
            }
            //   console.log();
          },
        });
      },
    });
  }

  var $inputPlatforms = $("#input-platforms");
  if ($inputPlatforms.length > 0) {
    $inputPlatforms.selectize({
      plugins: ["remove_button"],
      delimiter: ",",
      persist: false,
      placeholder: "Enter trading platform",
      onInitialize: function () {
        var $tradingPlatform = $("#trading-settings");
        toastr.clear();
        toastr.options = {
          closeButton: true,
          debug: false,
          newestOnTop: false,
          progressBar: false,
          positionClass: "toast-top-center",
          preventDuplicates: true,
          showDuration: "1000",
          hideDuration: "10000",
          timeOut: "2000",
          extendedTimeOut: "1000",
        };
        if ($tradingPlatform.length <= 0) {
          toastr.error("Can't initialize platform interface");
          return;
        }
        $tradingPlatform.on("submit", function (e) {
          var $self = $(this);
          e.preventDefault();
          $.ajax({
            url: $self.attr("action"),
            processData: false,
            method: "post",
            contentType: false,
            data: new FormData(this),
            success: function (res) {
              toastr.success(res.message);
              selectize_platform.clearOptions();
              selectize_platform.refreshOptions(true);
              if (res.data && res.data.platforms) {
                res.data.platforms.forEach((entry) => {
                  selectize_platform.addOption({
                    text: entry,
                    value: entry,
                  });
                });
              }
            },
            error: function () {
              toastr.error("Error Can't save.");
            },
          });
        });
      },
      create: function (input) {
        return {
          value: input,
          text: input,
        };
      },
    });
  }

  return {
    submit: function (form) {
      var data = new FormData(form);
      var item = selectize_platform.getItem(selectize_platform.getValue());
      data.set("platform", item.text());
      $.ajax({
        data: data,
        url: form.action,
        method: form.method,
        processData: false,
        contentType: false,
        enctype: "multipart/form-data",
        success: function (res) {
          toastr.success("Save Successfully");
        },
        error: function (err) {
          toastr.error("Error can't save");
        },
      });
    },
  };
};
