window.verificationCallback = function (options) {

  function filter(file, done) {
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(file.name)[1];
    ext = ext.toUpperCase();
    if (ext == "JPG" || ext == "JPEG" || ext == "PNG" || ext == 'PDF') {
      done();
    } else {
      done("Please select only supported picture files.");
    }
  }

  var headers = {
    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
  };

  var _this = {
    tab: "front",
    open: false,
    front: null,
    back: null,
    proof: null,
    docFullName: "",
    docShortName: "",
    dropzone: undefined,
    formData: [],
    tempFile: [],
    initialized: false,
    document: "",
    optionMenus: options,
    menuAction: function (index) {
      this.open = false;
      this.docFullName = _this.optionMenus[index].fullName;
      this.docShortName = _this.optionMenus[index].shortName;
    },
    submit: function (form) {
      var form = this.$refs.kyc;
      var formData = $(form).serializeArray();
      formData.push({ name: "front", value: _this.formData.front });
      formData.push({ name: "back", value: _this.formData.back });

      $.ajax({
        url: form.action,
        method: form.method,
        data: $.param(formData),
        success: function (result) {
          notify("success", result);
          var handle = setInterval(() => {
            clearInterval(handle);
            window.location.reload();
          }, 1000);
        },
        error: function (err) {
          notify("error", err.responseJSON);
          var handle = setInterval(() => {
            clearInterval(handle);
            window.location.reload();
          }, 1000);
        },
      });
    },
    proofDocument: function () {
      var form = this.$refs.form;
      var formData = $(form).serializeArray();
      formData.push({ name: "document", value: this.document });
      $.ajax({
        url: form.action,
        method: form.method,
        data: $.param(formData),
        headers: headers,
        success: function (result) {
          notify("success", result);
          console.log(result);
          var handle = setInterval(() => {
            clearInterval(handle);
            // window.location.reload();
          }, 1000);
        },
        error: function (err) {
          notify("error", err.responseJSON);
          var handle = setInterval(() => {
            clearInterval(handle);
            window.location.reload();
          }, 1000);
        }
      }
      );

    },
    init: function () {
      Dropzone.autoDiscover = false;
      if (!this.initialized) {
        this.initialized = true;
        var front = this.$refs.front;
        this.front = new Dropzone(front,
          {
            maxFiles: 1,
            maxFilesize: 5,
            addRemoveLinks: true,
            acceptedFiles: ".jpg,.jpeg,.png,.pdf",
            success: function (file, response) {
              _this.formData['front'] = response.filename;
              _this.tempFile['front'] = file;
            },
            accept: filter,
            headers: headers,
            url: "/user/kyc/uploads",
            clickable: front,
          });
        var back = this.$refs.back;
        this.back = new Dropzone(back,
          {
            maxFiles: 1,
            maxFilesize: 5,
            addRemoveLinks: true,
            acceptedFiles: ".jpg,.jpeg,.png,.pdf",
            success: function (file, response) {
              _this.formData['back'] = response.filename;
              _this.tempFile['back'] = file;
            },
            accept: filter,
            headers: headers,
            url: "/user/kyc/uploads",
            clickable: back,
          });

        var proof = this.$refs.proof;
        var route = proof.getAttribute('data-route');
        this.proof = new Dropzone(proof, {
          maxFiles: 1,
          maxFilesize: 5,
          addRemoveLinks: true,
          acceptedFiles: ".jpg,.jpeg,.png,.pdf",
          success: function (file, response) {
            _this.document = response.filename;
          },
          accept: filter,
          headers: headers,
          url: route,
          clickable: proof,
        });
      }
    },
  };

  return _this;
};

window.backupCode = function () {
  var _this = {
    type: 1,
    name: "secret",
    label: "2FA Token",
    btnName: "Use Backup Code",
    toBackup: function () {
      if (!this.type) {
        this.label = "2FA Token";
        this.name = "secret";
        this.btnName = "Use Backup Code";
        this.type = 1;
        return;
      }
      this.label = "Backup Token";
      this.name = "backup_token";
      this.btnName = "Use 2FA Token";
      this.type = 0;
    },
  };

  return _this;
};
