import DateRangePicker from 'vanillajs-datepicker/DateRangePicker';

window.managerUsers = function () {

    const elem = document.getElementById('range');
    const dateRangePicker = new DateRangePicker(elem, {
        container: ".relative",
        allowOneSidedRange: true,
        todayHighlight: true,
    });


    var userManager = Spruce.store("usermanager", {
        loading: true,
        startDate: null,
        stopDate: null,
        checkedAll: false,
        selections: [],
    });
    var table = $("#userslist-table").DataTable({
        serverSide: true,
        processing: true,
        ajax: {
            data: function (data) {
                for (var i = 0, len = data.columns.length; i < len; i++) {
                    if (!data.columns[i].search.value) delete data.columns[i].search;
                    if (data.columns[i].searchable === true)
                        delete data.columns[i].searchable;
                    if (data.columns[i].orderable === true)
                        delete data.columns[i].orderable;
                    if (data.columns[i].data === data.columns[i].name)
                        delete data.columns[i].name;
                }
                delete data.search.regex;
            },
        },
        columns: [{
                data: "checkbox",
                name: "checkbox",
                title: '<input type="checkbox" @click="selectAll()" :checked="checkedAll" class="appearance-none border border-gray-300 checked:bg-blue-600 checked:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0 h-5 ring-0 rounded-md w-5 p-0!">',
                orderable: false,
                searchable: false,
                className: "h-full p-1.5! sorting_disabled text-center text-gray-800 text-xs  font-semibold uppercase",
                width: 10,
            },
            {
                data: "first_name",
                name: "first_name",
                title: "First Name",
                orderable: true,
                searchable: true,
                // width: 100,
                className: "text-center text-xs  font-semibold uppercase text-gray-800",
            },
            {
                data: "last_name",
                name: "last_name",
                title: "Last Name",
                orderable: true,
                searchable: true,
                // width: 100,
                className: "text-center text-xs  font-semibold uppercase text-gray-800",
            },
            {
                data: "email",
                name: "email",
                title: "Email",
                orderable: true,
                searchable: true,
                width: 100,
                className: "text-center text-xs  font-semibold uppercase text-gray-800",
            },
            {
                data: "phone",
                name: "phone",
                title: "Phone",
                orderable: true,
                searchable: true,
                className: "text-center text-xs  font-semibold uppercase text-gray-800",
            },
            {
                data: "KYC",
                name: "KYC",
                title: "Kyc",
                orderable: false,
                searchable: false,
                className: "text-center text-xs  font-semibold uppercase text-gray-800",
            },
            {
                data: "status",
                name: "status",
                title: "Status",
                orderable: false,
                className: "text-center text-xs  font-semibold uppercase text-gray-800",
                searchable: false,
            },
            {
                data: "created_at",
                name: "created_at",
                title: "Created At",
                orderable: true,
                searchable: true,
                width: 150,
                className: "text-center text-xs  font-semibold uppercase text-gray-800",
            },
            {
                data: "action",
                name: "action",
                title: "",
                orderable: false,
                searchable: false,
                width: 20,
                className: "text-center text-xs  font-semibold uppercase text-gray-800",
            },
        ],
        responsive: true,
        autoWidth: false,
        drawCallback: function (settings) {
            for (var i = 0; i < table.rows().count(); i++) {
                _this.actions.push({
                    open: false,
                    block: false,
                });
            }
            var data = table.ajax.json().data;
            data.forEach((elem) => {
                userManager.selections[elem.id] = {
                    checked: false,
                    user_id: elem.user_id,
                    email: elem.email
                };
            });
        },
        dom: "rtip",
        // "select": true,
        order: [
            [1, "desc"]
        ],
        stateSave: true,
        buttons: [{
            extend: "export"
        }, {
            extend: "reload"
        }, {
            extend: "print"
        }, {
            extend: "excel"
        }],
        initComplete: function () {
            userManager.loading = false;
        },
    });

    var _this = {
        showDropdown: false,
        open: false,
        _token: "_token",
        loading: true,
        checkedAll: false,
        buckMailing: false,
        pageLength: 10,
        selected: 0,
        actions: [],
        selectAll: function () {
            this.checkedAll = !this.checkedAll;
            this.buckMailing = this.checkedAll;
            this.selected = table.ajax.json().data.length;
            $('input[type="checkbox"]').prop("checked", this.checkedAll);
            userManager.selections.forEach((__, index) => {
                userManager.selections[index].checked = _this.checkedAll;
            });
        },
        search: function (event) {
            table.search(event.target.value).draw();
        },
        triggerEvent: function (name) {
            table.buttons(`.buttons-${name}`).trigger();
        },
        exportPDF: function () {
            triggerEvent('pdf');
        },
        reload: function () {
            table.ajax.reload();
        },
        print: function () {
            triggerEvent('print');
        },
        exportExcel: function () {
            triggerEvent('excel');
        },
        exportCsv: function () {
            triggerEvent('csv');
        },
        onPageLength: function () {
            table.page.len(this.pageLength === "All" ? -1 : this.pageLength).draw();
        },
        init: function (startDate, stopDate) {
            table.page.len(10).draw();
            Spruce.store("usermanager").startDate = startDate;
            Spruce.store("usermanager").stopDate = stopDate;
        },
        sendBuckMail: function () {
            var ids = [];
            var emails = [];
            userManager.selections.forEach((elem) => {
                ids.push(elem.user_id);
                emails.push(elem.email);
            });
            emails = emails.filter((value, index) => emails.indexOf(value) === index);
            ids = ids.filter((value, index) => ids.indexOf(value) === index);
            mailer("id", ids, emails);
        },
        block: function (dom, type) {
            blockUserModal(dom.href, type);
        },
        accountBlocked: function () {
            table.ajax.reload();
        },
        accountDeleted: function () {
            table.ajax.reload();
        },
        onDelete: function (dom) {
            deleteUserModal(dom.href);
        },
        onLogin: function (dom) {
            $.ajax({
                url: dom.href,
                method: "post",
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
                },
                success: function (response) {
                    window.location.href= '/user/dashboard';
                },
                error: function () {
                },
            });
        }
    };

    return _this;
};