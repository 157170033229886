import AutoNumeric from 'autonumeric';
import { throttle } from 'lodash';
import AccountDetail from './AccountDetail';
import AddMoney from './AddMoney';
import CreateAccount from './CreateAccount';
import DemoAccounts from './DemoAccounts';
import FundAccount from './FundAccount';
import FundTransfer from './FundTransfer';
import GeneratePassword from './GeneratePassword';
import Leverage from './Leverage';
import LiveAccounts from './LiveAccounts';
import Statistic from './Statistic';
import TransferToWallet from './TransferToWallet';

export default class Application {

    constructor() {
        this.stats = new Statistic;
        this.demoAccounts = new DemoAccounts;
        this.liveAccounts = new LiveAccounts;
        this.leverage = new Leverage;
        this.fundTransfer = new FundTransfer;
        this.walletTransfer = new TransferToWallet;
        this.fundAccount = new FundAccount;
        this.generatePassword = new GeneratePassword;
        this.accountDetail = new AccountDetail;
        this.addMoney = new AddMoney;
        this.createAccount = new CreateAccount;

        var timeout = throttle(function () {
            console.log(timeout);
        }, 1000);
    }

    initialize() {
        var stats = this.stats;
        Spruce.store('stats', {
            walletBalance: stats.getWalletBalance() > 0,
        });
    }
};
