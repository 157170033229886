import AutoNumeric from "autonumeric";
import { isNull, isUndefined, spread } from "lodash";

export default class Statistic {
    constructor() {
        this.walletBalance = null;
        this.tradingBalance = null;
        this.tradingProfit = null;
        this.floatingProfit = null;
        this.balanceInfo = this.getUserBalanceInfo();

        window.addEventListener('initstat', () => this.initialize());
    }

    initialize() {
        var _this = this;

        if (!isUndefined(this.$refs)) {
            var tradeBalance = this.$refs.tradingBalance;
            if (AutoNumeric.getAutoNumericElement(tradeBalance) == null) {
                this.tradingBalance = new AutoNumeric(tradeBalance, {
                    currencySymbol: tradeBalance.getAttribute('data-symbol'),
                });
            }

            var walletBalance = this.$refs.walletBalance;
            if (AutoNumeric.getAutoNumericElement(walletBalance) == null) {
                this.walletBalance = new AutoNumeric(walletBalance, {
                    currencySymbol: walletBalance.getAttribute('data-symbol'),
                });
            }

            var tradingProfit = this.$refs.tradingProfit;
            if (AutoNumeric.getAutoNumericElement(tradingProfit) == null) {
                this.tradingProfit = new AutoNumeric(tradingProfit, {
                    currencySymbol: tradingProfit.getAttribute('data-symbol'),
                });
            }

            var floatingProfit = this.$refs.floatingProfit;
            if (AutoNumeric.getAutoNumericElement(floatingProfit) == null) {
                this.floatingProfit = new AutoNumeric(floatingProfit, {
                    currencySymbol: floatingProfit.getAttribute('data-symbol'),
                });
            }
            window.addEventListener('transferwalletsuccess', ({ detail }) => {

            });

            window.addEventListener('fundsuccess', ({ detail }) => {

            });
        }

        Spruce.store('stats', {
            hasBalance: _this.getWalletBalance() <= 0,
            hasTradingBalance: _this.getTradingBalance() <= 0,
        });
    }

    updateWalletStatus(value) {
        this.setWalletBalance(value.amount + this.getWalletBalance());
        this.setTradingBalance(Math.abs(this.getTradingBalance() - value.amount));
    }

    updateFundStatus(value) {
        this.setWalletBalance(Math.abs(this.getWalletBalance() - value.amount));
        this.setTradingBalance(value.amount + this.getTradingBalance());
    }

    getUserBalanceInfo() {
        if (document.URL.indexOf('admin') <= 0) {
            var result = $.ajax({
                dataType: "json",
                url: '/user/account/balance/info',
                async: false,
                headers: {
                    "X-CSRF-TOKEN": window._token
                }
            });
            return result.responseJSON;
        }
    }
    getWalletBalance() {
        if (isNull(this.walletBalance)) {
            const { balance } = this.balanceInfo;
            return balance;
        }
        return this.walletBalance.getNumber();
    }

    getTradingProfit() {
        if (isNull(this.tradingProfit)) {
            const { trading_profit } = this.balanceInfo;
            return trading_profit;
        }
        return this.tradingProfit.getNumber();
    }

    getTradingBalance() {
        if (isNull(this.tradingBalance)) {
            const { trading_balance } = this.balanceInfo;
            return trading_balance;
        }
        return this.tradingBalance.getNumber();
    }

    setTradingProfit(profit) {
        this.tradingProfit.set(profit);
        dispatch('wallet-profit-change', { balance: profit });
    }

    setWalletBalance(balance) {
        this.walletBalance.set(balance);
        dispatch('wallet-balance-change', { balance: balance });
        this.$store.stats.walletBalance = this.getWalletBalance() > 0;
    }

    setTradingBalance(balance) {
        this.tradingBalance.set(balance);
        dispatch('wallet-profit-change', { balance: balance });
    }
}
