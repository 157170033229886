import {isNull} from "lodash";

window.notification = function () {
    var _this = {
        init: function (data) {
            if (!isNull(data)) {
                notify(data.type, {
                    title: data.title,
                    message: data.message,
                });
            }
        },
    }

    return _this;
}