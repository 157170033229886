<template>
  <div class="px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
    <div class="relative p-2">
      <main ref="main">
        <div class="p-2 bg-white">
          <div class="flex items-center justify-between py-2" ref="filter">
            <div class="flex items-center justify-center gap-2">
              <button
                data-tooltip
                tooltip-title="Export Excel"
                class="
                  p-1.5
                  hover:bg-gray-200
                  transition-colors
                  duration-200
                  ease-linear
                  bg-gray-100
                  rounded-md
                  mr-5
                  ml-2
                "
                @click="onExport"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  role="img"
                  width="20"
                  height="20"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M13 5.828V17h-2V5.828L7.757 9.071L6.343 7.657L12 2l5.657 5.657l-1.414 1.414L13 5.828zM4 16h2v4h12v-4h2v4c0 1.1-.9 2-2 2H6c-1.1 0-2-.963-2-2v-4z"
                    fill="currentColor"
                    fill-rule="evenodd"
                  />
                </svg>
              </button>
              <date-picker
                v-model="value1"
                type="date"
                range
                placeholder="Select date range"
                @change="onRangeChange"
              ></date-picker>
              <button
                @click="onFilter"
                class="
                  p-1.5
                  hover:bg-gray-200
                  transition-colors
                  duration-200
                  ease-linear
                  bg-gray-100
                  rounded-md
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  role="img"
                  width="20"
                  height="20"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    d="M420.6 798h182.9V642H420.6zM411 561.4l9.5 16.6h183l9.5-16.6L811.3 226H212.7z"
                    fill-opacity=".15"
                    fill="currentColor"
                  />
                  <path
                    d="M880.1 154H143.9c-24.5 0-39.8 26.7-27.5 48L349 597.4V838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V597.4L907.7 202c12.2-21.3-3.1-48-27.6-48zM603.5 798H420.6V642h182.9v156zm9.5-236.6l-9.5 16.6h-183l-9.5-16.6L212.7 226h598.6L613 561.4z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
            <div class="flex items-center gap-1">
              <label for="search-filter">Search: </label>
              <input
                type="text"
                name="filter"
                id="search-filter"
                @input="onSearchInput"
                class="h-8 transition-colors duration-200 border-gray-400 rounded-md focus:border-blue-300 focus:outline-none"
              />
            </div>
          </div>

          <table ref="tableRef" class="display" style="width: 100%">
            <thead>
              <tr>
                <th
                  v-for="(column, index) in columns"
                  :key="index"
                  v-text="column.title"
                ></th>
              </tr>
            </thead>
          </table>
        </div>

        <EnrollmentDialog />
      </main>
    </div>
  </div>
</template>

<script>
import LinkManager from "./components/LinkManager.vue";
import EnrollmentDialog from './components/EnrollmentDialog.vue';
import DataTable from "datatables.net-dt";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

export default {
  components: {
    LinkManager,
    DatePicker,
    EnrollmentDialog
  },
  methods: {
    onFilter: function () {
      const startDate = moment(this.range[0]).format("MM-DD-YYYY");
      const stopDate = moment(this.range[1]).format("MM-DD-YYYY");
      this.table.ajax
        .url(
          "/api/ib/clients?start-date=" + startDate + "&end-date=" + stopDate
        )
        .load();
      this.table.draw();
    },
    onSearchInput: function (event) {
      if (this.table != null) {
        this.table.search(event.target.value, true, true, true).draw();
      }
    },
    onRangeChange: function (range) {
      this.range = range;
    },
    onExport: function () {
        this.table.buttons('.buttons-excel').trigger();
    },
  },
  data() {
    return {
      range: [],
      table: null,
      value1: [moment().subtract(3, 'month').toDate(), moment().toDate()],
      columns: [
        {
          data: "created_at",
          name: "created_at",
          title: "Date Created",
          orderable: true,
          searchable: true,
          className: "text-center text-xs font-semibold p-1",
        },
        {
          data: "client_name",
          name: "client_name",
          title: "Client",
          orderable: true,
          searchable: true,
          width: 60,
          className: "text-center text-xs font-semibold p-1",
        },
        {
          data: "mt4_account_id",
          name: "mt4_account_id",
          title: "Account",
          orderable: true,
          searchable: true,
          width: 60,
          className: "text-center text-xs font-semibold p-1",
        },
        {
          data: "tier",
          name: "tier",
          title: "Tier",
          orderable: true,
          searchable: true,
          className: "text-center text-xs font-semibold p-1",
        },
        {
          data: "balance",
          name: "balance",
          title: "Balance",
          orderable: true,
          searchable: true,
          className: "text-center text-xs font-semibold p-1",
        },
        {
          data: "commission",
          name: "commission",
          title: "Commission",
          orderable: true,
          searchable: true,
          className: "text-center text-xs font-semibold p-1",
        },
        {
          data: "dep",
          name: "dep",
          title: "Deposits",
          orderable: true,
          searchable: true,
          className: "text-center text-xs font-semibold p-1",
        },
        {
          data: "lot",
          name: "lot",
          title: "Lots",
          orderable: true,
          searchable: true,
          className: "text-center text-xs font-semibold p-1",
        },
      ],
    };
  },
  mounted() {
    var groupColumn = 1;
    this.table = new DataTable(this.$refs.tableRef,{
      columnDefs: [{ visible: false, targets: groupColumn }],
      order: [[groupColumn, "asc"]],
      displayLength: 25,
      drawCallback: function (settings) {
        var api = this.api();
        var rows = api.rows({ page: "current" }).nodes();
        var last = null;

        api
          .column(groupColumn, { page: "current" })
          .data()
          .each(function (group, i) {
            if (last !== group) {
              $(rows)
                .eq(i)
                .before(
                  '<tr class="font-semibold group"><td colspan="11">' +
                    group +
                    "</td></tr>"
                );

              last = group;
            }
          });
      },

      serverSide: true,
      processing: true,
      ajax: {
        type: "GET",
        url: "/api/ib/clients",
        // data: function (data) {
        // },
      },
      columns: this.columns,
      autoWidth: true,
      responsive: true,
      language: {
        emptyTable: "Partner table empty",
        paginate: {
          previous:
            '<svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 icon icon-tabler icon-tabler-chevrons-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n                                <polyline points="11 7 6 12 11 17" />\n                                <polyline points="17 7 12 12 17 17" />\n                            </svg>',
          next: '<svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 icon icon-tabler icon-tabler-chevrons-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n                                <polyline points="7 7 12 12 7 17" />\n                                <polyline points="13 7 18 12 13 17" />\n                            </svg>',
        },
      },
      dom: "rtip",
      buttons: [
        {
          name: "pdfHtml5",
          extend: "pdfHtml5",
          exportOptions: {
            columns: function (idx, data, node) {
              var $column = table.column(idx);
              return (
                ($column.visible() &&
                  $(node).attr("data-visible-in-export") != "false") ||
                $(node).attr("data-force-export") == "true"
              );
            },
          },
          orientation: "landscape",
          action: function (e, dt, button, config) {
            $.fn.DataTable.ext.buttons.pdfHtml5.action.call(
              this,
              e,
              dt,
              button,
              config
            );
          },
        },
        {
          extend: "excel",
        },
      ],
    });
    // new Datatable();
  },
};
</script>