
window.composer = function () {
    return {
        cc: false,
        bcc: false,
        showCc: function () {
            this.cc = !this.cc;
        },
        showBcc: function () {
            this.bcc = !this.bcc;
        }
    };
}
