export default class CreateAccount {
    constructor() {
        Spruce.store('_ca', {
            wait: false,
        });
    }

    close() {
        this.$store.__account.open = true;
        this.$store._ca.wait= false;
    }
    submit() {
        var _this = this;
        this.$store._ca.wait= true;
        var form = this.$refs.form;
        $.ajax({
            data: $(form).serialize(),
            url: form.action,
            method: form.method,
            success: function (result) {
                _this.close();
                dispatch('showdetail', result);
            },
            error: function (result) {
                _this.close();
                notify('error', result.responseJSON);
            }
        });
    }
}

