import "bootstrap-pincode-input";
import moment, { duration } from "moment";

window.phoneVerification = function () {
  Spruce.store('_verify', {
    disabled: false,
  });
  var _this = {
    __t: 0,
    ellapse: 0,
    object: null,
    recound: function () {
      var _verify = Spruce.store('_verify');
      if(_this.ellapse > 0){
        var formatted = moment.utc(_this.ellapse*1000).format("mm:ss");
        _this.object.innerText = formatted;
      }
      if (_this.ellapse-- <= 0 && _verify.disabled) {
        _verify.disabled = false;
        _this.object.innerText = 'Resend';
        clearInterval(_this.__t);
      }
    },
    resend: function () {
      var b = this.$refs.resend;

      $.ajax({
        url: b.getAttribute('href'),
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': _token
        },
        success: (second) => {
          var _verify = Spruce.store('_verify');
          _verify.disabled = true;
          _this.ellapse = Number(second);
          _this.__t = setInterval(_this.recound, 1000);
          _this.object = b;
        },
        error: function () {
          notify('error', {
            'title': "Phone Verification",
            'message': "Internal error, check network"
          });
        }
      })
    }
  };

  return _this;
}