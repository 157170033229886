import AutoNumeric from "autonumeric";

export default class AddMoney {
    constructor() {
        this.id = null;

        Spruce.store('add_money', {
            disabled: true,
            open: false,
        });
    }

    initialize() {
        window.addEventListener('add-money', ({ detail }) => this.show(detail));
    }

    show(detail) {
        this.id = detail.selected;
        this.$store.add_money.open = true;

        var amount = this.$refs.amount;
        if (AutoNumeric.getAutoNumericElement(amount) == null) {
            this.amount = new AutoNumeric(amount, {
                currencySymbol: amount.getAttribute('data-symbol'),
                maximumValue: amount.getAttribute('max'),
                decimalPlacesRawValue: null,
            });
        }
    }

    add() {
        var form = this.$refs.form;
        var _this = this;
        $.ajax({
            url: `${form.action}/${this.id}`,
            method: form.method,
            data: $.param({amount: this.amount.getNumber()}),
            success: function (result) {
                application.demoAccounts.items[_this.id].updateBalance(_this.amount.getNumber());
                notify('success', result);
            },
            error: function (result) {
                notify("error", result.responseJSON);
            },
        });
    }
}