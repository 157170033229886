import datepicker from "vanillajs-datepicker";

window.profileInitialisation = function () {

  var $dateOfBirth = $('#date_of_birth');
  if ($dateOfBirth.length > 0) {
    const datepicker = new Datepicker($dateOfBirth[0], {
      todayHighlight: true,
      format: "dd-mm-yyyy",
    });
  }


  var $select_bdr = $("#country");
  if ($select_bdr.length > 0) {
    $select_bdr.each(function () {
      var $this = $(this);
      $this.selectize({
        create: "text"
      });
    });
  }

  return {
    updateProfile: function (form) {
      $.ajax({
        url: form.action,
        method: "put",
        data: $(form).serialize(),
        success:  () =>{
          notify("success", {
            title: "User Profile",
            message: "Profile update successful",
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        },
        error: function (err) {
          notify("error", {
            title: "Unable to save",
            message: err.responseText,
          });
        },
      });
    },
  };
};


window._agent = function () {
  return {
    loading: false,
    submit: function (event) {
      this.loading = true;
      $.ajax({
        url: event.target.action,
        method: 'post',
        data: $(event.target).serialize(),
        success: (data) => {
          this.loading = false;
          if (data.status === 1) {
            notify("success", data.message);
            setTimeout(() => {
              location.reload();
            }, 1000);
          }
        },
        error: (data) => {
          this.loading = false;
          const _data = data.responseJSON;
          notify("error", _data.message);
        }
      });
    }
  }
}