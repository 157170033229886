window.settings = function () {
  // $store.settings.g2fa
  var settings = Spruce.store("settings", {
    recoveryTokens: [],
    enabled2FA: false,
    url: "",
    enabledStr: "",
    g2fa: null,
    initG2fa: function (el) {
      if (settings.g2fa == null) return el.src;
      return settings.g2fa;
    },
    loading: false,
    backup: {
      loading: false,
    },
  });

  var _this = {
    fetch: function (url) {
      settings.url = url;
      $.ajax({
        url: url,
        async: false,
        success: function (response) {
          setTimeout(() => {
            settings.recoveryTokens = response.recoveryTokens;
            settings.enabled2FA = response.g2fa_enabled;
            settings.g2fa = response.g2fa;
            settings.backup.loading = false;
            if (settings.enabled2FA) {
              settings.enabledStr = "Disable 2FA";
            } else {
              settings.enabledStr = "Enable 2FA";
            }
          }, 1000);
        },
        error: function (err) {
          notify("error", {
            title: "2FA Recovery",
            message: "Can't continue, network problem.",
          });
          settings.backup.loading = false;
        },
      });
    },
    init: function (url) {
      _this.fetch(url);
    },
    recoveryTokens: [],
    split: function (_token) {
      let token = String(_token);
      return `${token.substr(0, 10)} - ${token.substr(10, 10)}`;
    },
    enable2FA: function (innerObj) {
      settings.loading = true;
      setTimeout(() => {
        $.ajax({
          url: settings.url,
          async: false,
          method: "post",
          data: { "enable-2fa": !settings.enabled2FA },
          success: function (response) {
            settings.enabled2FA = response.g2fa_enabled;
            settings.g2fa = response.g2fa;
            settings.loading = false;
            if (response.g2fa_enabled) {
              innerObj.innerText = "Disable 2FA";
            } else {
              innerObj.innerText = "Enable 2FA";
            }
          },
          error: function (err) {
            settings.loading = false;
            notify("error", {
              title: "2FA Recovery",
              message: "Can't continue, network problem.",
            });
          },
        });
      }, 500);
    },
    regenerateBackupToken: function (url) {
      settings.backup.loading = true;
      _this.init(url);
    },
  };
  return _this;
};
