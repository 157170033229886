import AutoNumeric from "autonumeric";
import { isUndefined, update } from "lodash";

export default class TransferToWallet {
    constructor() {
        this.amount = null;
        this.id = null;
    }

    initialize() {
        Spruce.store('wallet', {
            open: false,
            disable: true,
        });

        window.addEventListener('wallet-transfer', ({ detail }) => this.show(detail));
    }

    onChange() {
        this.$store.wallet.disable = !this.amount.getNumber() > 0;
        this.$refs.__amount.value = this.amount.getNumber();
    }

    show(detail) {
        this.id = detail.account;
        this.$store.wallet.open = true;
        var amount = this.$refs.amount;
        // if (AutoNumeric.getAutoNumericElement(amount) == null) {
        //     this.amount = new AutoNumeric(amount, {
        //         currencySymbol: amount.getAttribute('data-symbol'),
        //         maximumValue: detail.balance,
        //         decimalPlaces: 0,
        //     });
        // }
    }

    transfer() {
        // if (this.amount) {
        //     return this.amount.getNumber();
        // }
    }

    onTransfer() {
        var _this = this;
        var form = this.$refs.form;
        var amount = this.$refs.amount.value;
        var payload = { account: this.id, amount: amount };
        // var fields = [...$(form).serializeArray(), { name: 'amount', value: amount }];
        axios.post(`${form.action}/${this.id}`, payload, {
            withCredential: true, headers: {
                "Context-Type": "application/json"
            }
        }).then(({ data }) => {
            notify('success', data);
            dispatch('transferwalletsuccess', payload);
            // if (!isUndefined(application.stats)) {
            //     application.stats.updateWalletStatus(payload);
            // }
            var timeout = setTimeout(() => {
                window.location.reload();
                clearTimeout(timeout);
            }, 1000);
        }).catch(() => {
            dispatch('transferwalletfail', payload);
            notify('error', {
                title: "Wallet Transfer fail",
                message: "Unable to transfer to wallet"
            });
        })
        // $.ajax({
        //     url: `${form.action}/${this.id}`,
        //     method: form.method,
        //     data: fields,
        //     success: function (result) {

        //     },
        //     error: function (result) {
        //         notify('error', result);
        //         dispatch('transferwalletfail', payload);
        //     }
        // });
    }
}