import Dropzone from "dropzone";

Dropzone.autoDiscover = false;

const toastr = require("toastr");

$(function () {
  var __platforms = null;
  var __leverage = null;
  var $createPromotion = $("#create-promotion");
  var $updatePromotion = $("#update-promotion");

  tinymce.init({
    selector: "textarea#usage_terms",
    height: 500,
    theme: "modern",
    plugins:
      "print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount contextmenu colorpicker textpattern help",
    toolbar1:
      "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | code",
    image_advtab: true,
  });

  tinymce.init({
    selector: "textarea#descritpion",
    height: 300,
    theme: "modern",
    plugins:
      "print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount contextmenu colorpicker textpattern help",
    toolbar1:
      "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | code",
    image_advtab: true,
  });

  var $defaultLeverage = $("#default-leverage");
  if ($defaultLeverage.length > 0) {
    $defaultLeverage.selectize({
      onInitialize: function () {
        __leverage = this;
      },
      plugins: ["remove_button"],
      delimiter: ",",
      persist: false,
      maxOptions: 1,
      createFilter: function (input) {
        if (this.items.length == 1) return false;
        var match;
        match = input.match(/1:[0-9]+/gm);
        if (match) return true;
        return false;
      },
      create: function (input) {
        return {
          value: input,
          text: input,
        };
      },
    });
  }

  var $targetPlatforms = $("#target-platforms");

  if ($targetPlatforms.length > 0) {
    toastr.clear();
    toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      preventDuplicates: true,
      positionClass: "toast-top-center",
      showDuration: "1000",
      hideDuration: "10000",
      timeOut: "2000",
      extendedTimeOut: "1000",
    };

    $targetPlatforms.selectize({
      onInitialize: function () {
        __platforms = this;
        $.ajax({
          url: "/admin/promotions/initialize",
          contentType: "json",
          success: function (res) {
            console.log(res.data);
            if (res.data) {
              res.data.forEach((element) => {
                console.log(element);
                __platforms.addOption({
                  text: element.name,
                  value: element.name,
                });
              });
            }
          },
          error: function () {
            toastr.error("Unable to initialize components");
          },
        });
      },
      plugins: ["remove_button"],
      delimiter: ",",
      persist: false,
      create: function (input) {
        return {
          value: input,
          text: input,
        };
      },
    });
  }

  if ($createPromotion.length > 0) {
    var $banner = $("#banner-image");
    if ($banner.length > 0) {
      var dropzone = $banner.dropzone({
        maxFiles: 1,
        maxFilesize: 5,
        addRemoveLinks: true,
        acceptedFiles: ".jpg,.jpeg,.png",
        sending: function (file, xhr, formData) {
          if ($createPromotion) {
            formData.append(
              "_token",
              $createPromotion.find('input[name="_token"]').attr("value")
            );
          } else {
            formData.append(
              "_token",
              $updatePromotion.find('input[name="_token"]').attr("value")
            );
          }
        },
        accept: function (file, done) {
          var re = /(?:\.([^.]+))?$/;
          var ext = re.exec(file.name)[1];
          ext = ext.toUpperCase();
          if (ext == "JPG" || ext == "JPEG" || ext == "PNG") {
            done();
          } else {
            done("Please select only supported picture files.");
          }
        },
        init: function () {
          this.on("success", function (file, response) {
            $createPromotion.data("image", response.filename);
          });
        },
        url: "/admin/promotions/uploads",
      });
    }

    var $createPromotion = $("#create-promotion");
    if ($createPromotion.length > 0) {
      $createPromotion.on("submit", function (e) {
        e.preventDefault();
        var $self = $(this);
        var formSerialized = $self.serializeArray();
        formSerialized.push({
          name: "image",
          value: $createPromotion.data("image"),
        });
        var $btn = $("#btn-create-promotion");
        toastr.clear();
        toastr.options = {
          closeButton: true,
          debug: false,
          newestOnTop: false,
          progressBar: false,
          positionClass: "toast-top-center",
          preventDuplicates: true,
          showDuration: "1000",
          hideDuration: "10000",
          timeOut: "2000",
          extendedTimeOut: "1000",
        };
        $.ajax({
          url: $self.attr("action"),
          method: "post",
          processData: false,
          beforeSend: function () {
            if ($btn.length > 0) {
              $btn.find(":last").css("display", "none");
            }
            $btn.find(":first").css("display", "block");
          },
          data: $.param(formSerialized),
          success: function (res) {
            toastr.success("Created Successfully");
            if ($btn.length > 0) $btn.find(":last").css("display", "block");
            $btn.find(":first").css("display", "none");
          },
          error: function (err) {
            toastr.error(
              "Can't create promotion, check your network or parameters."
            );
            if ($btn.length > 0) $btn.find(":last").css("display", "block");
            $btn.find(":first").css("display", "none");
          },
        });
      });
    }
  }

  var $updatePromotion = $("#update-promotion");
  if ($updatePromotion.length > 0) {
    var $banner = $("#banner-image");
    if ($banner.length > 0) {
      var dropzone = $banner.dropzone({
        maxFiles: 1,
        maxFilesize: 5,
        addRemoveLinks: true,
        acceptedFiles: ".jpg,.jpeg,.png",
        sending: function (file, xhr, formData) {
          formData.append(
            "_token",
            $updatePromotion.find('input[name="_token"]').attr("value")
          );
        },
        accept: function (file, done) {
          var re = /(?:\.([^.]+))?$/;
          var ext = re.exec(file.name)[1];
          ext = ext.toUpperCase();
          if (ext == "JPG" || ext == "JPEG" || ext == "PNG") {
            done();
          } else {
            done("Please select only supported picture files.");
          }
        },
        init: function () {
          var arr = String($updatePromotion.attr("action")).split("/");
          var id = arr[arr.length - 1];
          var self = this;
          $.ajax({
            data: { image: "show" },
            headers: {
              "X-CSRF-TOKEN": $updatePromotion
                .find('input[name="_token"]')
                .attr("value"),
            },
            success: function (res) {
              var thumbnail = `${window.location.protocol}//${window.location.host}/${res.image}`;
              var mockFile = {
                name: res.name,
                size: res.size,
                status: Dropzone.ADDED,
                accepted: true,
                url: thumbnail,
              };
              self.emit("addedfile", mockFile);
              self.emit("thumbnail", mockFile, thumbnail);
              self.emit("complete", mockFile);
              self.files.push(mockFile);
              $updatePromotion.data("image", res.name);
            },
            error: function () {
              toastr.clear();
              toastr.options = {
                closeButton: true,
                debug: false,
                newestOnTop: false,
                progressBar: false,
                positionClass: "toast-top-center",
                preventDuplicates: true,
                showDuration: "1000",
                hideDuration: "10000",
                timeOut: "2000",
                extendedTimeOut: "1000",
              };

              toastr.error("Can't initialize image preview");
            },
          });

          this.on("success", function (file, response) {
            $updatePromotion.data("image", response.filename);
          });
        },
        url: "/admin/promotions/uploads",
      });
    }
  }
  $updatePromotion.on("submit", function (e) {
    e.preventDefault();
    var $self = $(this);
    var formSerialized = $self.serializeArray();
    formSerialized.push({
      name: "image",
      value: $updatePromotion.data("image"),
    });
    var $btn = $("#btn-update-promotion");
    toastr.clear();
    toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: "toast-top-center",
      preventDuplicates: true,
      showDuration: "1000",
      hideDuration: "10000",
      timeOut: "2000",
      extendedTimeOut: "1000",
    };
    $.ajax({
      url: $self.attr("action"),
      method: "PUT",
      processData: false,
      beforeSend: function () {
        if ($btn.length > 0) {
          $btn.find(":last").css("display", "none");
        }
        $btn.find(":first").css("display", "inherit");
      },
      data: $.param(formSerialized),
      success: function () {
        toastr.success("Updated Successfully");
        if ($btn.length > 0) $btn.find(":last").css("display", "inherit");
        $btn.find(":first").css("display", "none");
      },
      error: function () {
        toastr.error(
          "Can't update promotion, check your network or parameters."
        );
        if ($btn.length > 0) $btn.find(":last").css("display", "inherit");
        $btn.find(":first").css("display", "none");
      },
    });
  });

  var $promotionCard = $(".promotion-card");
  if ($promotionCard.length > 0) {
    var $delete = $promotionCard.find(" a:eq(1)");

    if ($delete.length > 0) {
      $delete.each(function (__, element) {
        $(element).on("click", function (event) {
          event.preventDefault();
          sweetAlert({
            title: "Are you sure you want to delete this?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              $.ajax({
                url: $(element).attr("href"),
                method: "delete",
                success: function () {
                  sweetAlert("Successfully Deleted", {
                    icon: "success",
                  }).then(() => {
                    window.location.reload();
                  });
                },
                error: function () {
                  sweetAlert("Failure can't delete", {
                    icon: "danger",
                  });
                },
              });
            }
          });
        });
      });
    }
  }
});
