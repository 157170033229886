import { camelCase } from "lodash";

export default class Leverage {
    constructor() {
        this.open = false;
        this.disabled = false;
        this.choosen = null;
        this.leverages = [];
        this.id = null;

        Spruce.store('leverage', {
            open: false,
        });

        window.addEventListener('leverage-edit', (event) => this.show(event.detail));
    }

    show(detail) {
        application.$store.leverage.open = true;
        this.id = detail.id;
        var leverages = detail.leverages;
        var selections = this.$refs.selections;
        for (var index = 0; index < leverages.length; index++) {
            var selected = parseInt(leverages[index]) === detail.choosen;
            selections.options[index] = new Option(`1:${leverages[index]}`, leverages[index], false, selected);
        }
    }
    confirm() {
        var options = this.$refs.selections.options;
        var leverage = options[options.selectedIndex].value;
        var id = this.id;
        $.ajax({
            url: `${this.$refs.form.action}/${this.id}`,
            method: this.$refs.form.method,
            data: $(this.$refs.form).serialize(),
            success: function (result) {
                notify('success', result);
                dispatch('leverage-changed', { id: id, leverage: leverage });
            },
            error: function (result) {
                notify('error', result);
            }
        });
    }
}

