window.deleteAccount = function () {
    var deleteAccount = Spruce.store("delete_account", {
        open: false
    });

    toastr.clear();
    toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: "toast-top-center",
        preventDuplicates: true,
        showDuration: "1000",
        hideDuration: "10000",
        timeOut: "2000",
        extendedTimeOut: "1000",
    };


    var _this = {
        target: null,
        onDelete: function () {
            $.ajax({
                url: _this.target,
                method: 'delete',
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
                },
                success: function () {
                    toastr.success('Delete Successful');
                    deleteAccount.open = false;
                    window.dispatchEvent(
                        new CustomEvent("account-deleted", { detail: { href: this.target } })
                    );
                },
                error: function () {
                    toastr.error('Unable to block account, please check internet connection');
                }
            });
        },
        onClose: function () {
            deleteAccount.open = false;
        },
        onHandle: function (detail) {
            deleteAccount.open = true;
            this.target = detail.href;
            console.log(detail);
        }
    }
    return _this;
};


window.deleteUserModal = function (href) {
    window.dispatchEvent(
        new CustomEvent("delete-user", { detail: { href: href } })
    );
};
