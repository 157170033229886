export default class GeneratePassword {
    constructor() {
        this.targetUrl = '';
    }

    initialize() {
        Spruce.store('gpass', {
            open: false,
            idle: false,
        });

        window.addEventListener('passwordreset', ({ detail }) => this.handle(detail));
    }

    handle(detail) {
        this.$store.gpass.open = true;
        this.targetUrl = detail.targetUrl;
    }

    close() {
        this.$store.gpass.open = false;
        this.$store.gpass.idle = false;
    }

    generate() {
        var gpass = this.$store.gpass;
        var formData = new FormData();
        formData.set('_token', _token);
        gpass.idle = true;
        $.ajax({
            url: this.targetUrl,
            data: formData,
            processData: false,
            success: function (result) {
                gpass.open = false;
                dispatch('showdetail', result);
            },
            error: function (result) {
                notify('error', result.responseJSON);
                gpass.open = false;
            }
        })
    }
}

