import DateRangePicker from "vanillajs-datepicker/DateRangePicker";

window.manageVerification = function () {
  const BTN_PREFIX = "buttons";

  $.ajaxSetup({
    headers: {
      "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
  });

  const elem = document.getElementById("range");
  const dateRangePicker = new DateRangePicker(elem, {
    container: ".relative",
    allowOneSidedRange: true,
    todayHighlight: true,
  });

  var manageVerification = Spruce.store("manage_verification", {
    loading: true,
    startDate: null,
    stopDate: null,
    checkedAll: false,
    selections: [],
  });

  var table = $("#kyc-table").DataTable({
    serverSide: true,
    processing: true,
    ajax: {
      data: function (data) {
        for (var i = 0, len = data.columns.length; i < len; i++) {
          if (!data.columns[i].search.value) delete data.columns[i].search;
          if (data.columns[i].searchable === true)
            delete data.columns[i].searchable;
          if (data.columns[i].orderable === true)
            delete data.columns[i].orderable;
          if (data.columns[i].data === data.columns[i].name)
            delete data.columns[i].name;
        }
        delete data.search.regex;
      },
    },
    columns: [{
        data: "checkbox",
        name: "checkbox",
        title: '<input type="checkbox" @click="selectAll()" :checked="checkedAll" class="appearance-none border border-gray-300 checked:bg-blue-600 checked:border-transparent focus:outline-none focus:ring-0 focus:ring-offset-0 h-5 ring-0 rounded-md w-5 p-0!">',
        orderable: false,
        searchable: false,
        className: "h-full p-1.5! sorting_disabled text-center text-gray-800 text-xs  font-semibold uppercase",
        width: 10,
      },
      {
        data: "fullname",
        name: "fullname",
        title: "Full Name",
        orderable: true,
        searchable: true,
        width: 200,
        className: "text-center font-semibold uppercase text-xs text-gray-800",
      },
      {
        data: "email",
        name: "email",
        title: "Email",
        orderable: true,
        searchable: true,
        className: "text-center font-semibold uppercase text-xs text-gray-800",
        width: 130,
      },
      {
        data: "type",
        name: "type",
        title: "Type",
        orderable: true,
        searchable: true,
        className: "text-center font-semibold uppercase text-xs text-gray-800",
        width: 60,
      },
      {
        data: "front",
        name: "front",
        title: "Front",
        orderable: false,
        searchable: false,
        className: "text-center font-semibold uppercase text-xs text-gray-800",
        width: 100,
      },
      {
        data: "back",
        name: "back",
        title: "Back",
        orderable: false,
        searchable: false,
        className: "text-center font-semibold uppercase text-xs text-gray-800",
        width: 100,
      },
      {
        data: "status",
        name: "status",
        title: "Status",
        orderable: false,
        searchable: false,
        className: "text-center font-semibold uppercase text-xs text-gray-800",
        width: 100,
      },
      {
        data: "action",
        name: "action",
        title: "",
        orderable: false,
        searchable: false,
        width: 60,
        className: "text-center font-semibold uppercase text-xs text-gray-800",
      },
    ],
    drawCallback: function (settings) {
      for (var i = 0; i < table.rows().count(); i++) {
        _this.actions.push({
          open: false,
          block: false,
        });
      }
      var data = table && table.ajax.json().data;
      if (data) {
        data.forEach((item) => {
          _this.checkbox[item.id] = {
            email: $(item.email).text(),
            checked: false,
            user_id: item.id,
          };
        });
      }
    },
    buttons: [{
        extend: "export",
      },
      {
        extend: "reload",
      },
      {
        extend: "print",
      },
      {
        extend: "excel",
      },
    ],
    responsive: true,
    autoWidth: false,
    stateSave: true,
    dom: "rtip",
    order: [
      [1, "desc"]
    ],
    initComplete: function () {
      manageVerification.loading = false;
      window.addEventListener("refresh-table", function () {
        table.ajax.reload();
      });
    },
  });

  var _this = {
    counter: 0,
    loading: true,
    showDropdown: false,
    open: false,
    _token: "_token",
    loading: true,
    checkedAll: false,
    buckMailing: false,
    pageLength: 10,
    selected: 0,
    buckMenu: false,
    actions: [],
    checkbox: [],
    search: function (event) {
      table.search(event.target.value).draw();
    },
    exportPDF: function () {
      this.triggerThis("pdf");
    },
    reload: function () {
      table.ajax.reload();
    },
    print: function () {
      this.triggerThis("print");
    },
    exportExcel: function () {
      this.triggerThis("excel");
    },
    exportCsv: function () {
      this.triggerThis("csv");
    },
    triggerThis: function (name) {
      table.buttons(`.${BTN_PREFIX}-${name}`).trigger();
    },
    onPageLength: function () {
      table.page.len(this.pageLength === "All" ? -1 : this.pageLength).draw();
    },
    makeRequest: function (url, type, SuccessCallback, FailureCallback) {
      $.ajax({
        url: url,
        method: type,
        success: SuccessCallback,
        error: FailureCallback,
      });
    },
    init: function (startDate, stopDate) {
      table.page.len(50).draw();
      Spruce.store("manage_verification").startDate = startDate;
      Spruce.store("manage_verification").stopDate = stopDate;
    },
    onApprove: function (dom) {
      approveKyc(dom.href);
    },
    onDecline: function (dom) {
      declineKyc(dom.href);
    },
    onValidate: function (dom) {
      validateKyc(dom.href, 0);
    },
    onInvalidate: function (dom) {
      validateKyc(dom.href, 1);
    },
    selectAll: function () {
      this.checkedAll = !this.checkedAll;
      this.buckMailing = this.checkedAll;
      this.checkbox.forEach((value) => {
        value.checked = !value.checked;
      });
    },
    select: function () {
      var selected = this.checkbox.filter((value) => value.checked === true);
      var rows = table && table.rows().count();
      this.checkedAll = selected.length >= 2;
      this.buckMailing = this.checkedAll;
      if (selected.length === rows) {
        this.checkedAll = true;
        this.buckMailing = true;
      }
    },
    sendBuckMail: function () {
      var emails = [];
      var ids = [];
      var selected = this.checkbox.filter((value) => value.checked === true);
      selected.forEach((value) => {
        emails.push(value.email);
        ids.push(value.user_id);
      });
      mailer(
        "id",
        ids,
        emails.filter((value, index) => emails.indexOf(value) === index)
      );
    },
    onDelete: function (dom) {
      deleteKycRecord(dom.href, []);
    },
    onBuckDelete: function (dom) {
      var ids = [];
      var selected = this.checkbox.filter((value) => value.checked === true);
      selected.forEach((value) => {
        ids.push(value.user_id);
      });
      deleteKycRecord(
        dom.href,
        ids.filter((value, index) => ids.indexOf(value) === index)
      );
    },
  };

  return _this;
};

window.approveUserKyc = function () {
  var userKyc = Spruce.store("verify_kyc", {
    open: false,
  });

  var _this = {
    init: function () {
      toastr.clear();
      toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: "toast-top-center",
        preventDuplicates: true,
        showDuration: "1000",
        hideDuration: "10000",
        timeOut: "2000",
        extendedTimeOut: "1000",
      };
    },
    onHandle: function (detail) {
      userKyc.open = true;
      this.target = detail.href;
    },
    target: null,
    onApprove: function () {
      $.ajax({
        url: _this.target,
        method: "post",
        headers: {
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        success: function () {
          toastr.success("Approved Successful");
          userKyc.open = false;
          window.dispatchEvent(
            new CustomEvent("refresh-table", {
              detail: null,
            })
          );
        },
        error: function () {
          toastr.error(
            "Unable to approve KYC, please check internet connection"
          );
        },
      });
    },
    onClose: function () {
      userKyc.open = false;
    },
  };

  return _this;
};

window.approveKyc = function (href) {
  window.dispatchEvent(
    new CustomEvent("approve-kyc", {
      detail: {
        href: href,
      },
    })
  );
};

window.declineKycUser = function () {
  var declineUserKyc = Spruce.store("decline_kyc", {
    open: false,
  });

  toastr.clear();
  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: "toast-top-center",
    preventDuplicates: true,
    showDuration: "1000",
    hideDuration: "10000",
    timeOut: "2000",
    extendedTimeOut: "1000",
  };
  var _this = {
    onHandle: function (detail) {
      declineUserKyc.open = true;
      this.target = detail.href;
    },
    target: null,
    onDecline: function () {
      $.ajax({
        url: _this.target,
        method: "post",
        headers: {
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        success: function () {
          toastr.success("Declined Successful");
          declineUserKyc.open = false;
          window.dispatchEvent(
            new CustomEvent("refresh-table", {
              detail: null,
            })
          );
        },
        error: function () {
          toastr.error(
            "Unable to decline KYC, please check internet connection"
          );
        },
      });
    },
    onClose: function () {
      declineUserKyc.open = false;
    },
  };

  return _this;
};

window.declineKyc = function (href) {
  window.dispatchEvent(
    new CustomEvent("decline-kyc", {
      detail: {
        href: href,
      },
    })
  );
};

window.validateDocument = function () {
  var validateUserKyc = Spruce.store("validate_kyc", {
    open: false,
  });

  var _this = {
    init: function () {
      toastr.clear();
      toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: "toast-top-center",
        preventDuplicates: true,
        showDuration: "1000",
        hideDuration: "10000",
        timeOut: "2000",
        extendedTimeOut: "1000",
      };
    },
    onHandle: function (detail) {
      validateUserKyc.open = true;
      this.target = detail.href;
      this.type = detail.type;
    },
    target: null,
    type: 0,
    onValidate: function () {
      $.ajax({
        url: _this.target,
        data: {
          invalidate: _this.type,
        },
        method: "post",
        headers: {
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        success: function () {
          toastr.success(
            _this.type === 0 ?
            "Validation Successful" :
            "Invalidation Successful"
          );
          validateUserKyc.open = false;
          window.dispatchEvent(
            new CustomEvent("refresh-table", {
              detail: null,
            })
          );
        },
        error: function () {
          toastr.error(
            "Unable to validate or invalid Document, please check internet connection or try again"
          );
        },
      });
    },
    onClose: function () {
      validateUserKyc.open = false;
    },
  };

  return _this;
};

window.validateKyc = function (href, type) {
  window.dispatchEvent(
    new CustomEvent("validate-kyc", {
      detail: {
        href: href,
        type: type,
      },
    })
  );
};

window.handleDelete = function () {
  $.ajaxSetup({
    headers: {
      "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    }
  })
  var deleteKyc = Spruce.store("delete_kyc", {
    open: false,
  });

  var _this = {
    target: null,
    records: [],
    init: function () {
      toastr.clear();
      toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: "toast-top-center",
        preventDuplicates: true,
        showDuration: "1000",
        hideDuration: "10000",
        timeOut: "2000",
        extendedTimeOut: "1000",
      };
    },
    onDelete: function () {
      if (this.records.length == 0) {
        $.ajax({
          url: _this.target,
          method: "delete",
          success: function () {
            toastr.success("Record Deleted");
            deleteKyc.open = false;
            window.dispatchEvent(
              new CustomEvent("refresh-table", {
                detail: null,
              })
            );
          },
          error: function () {
            toastr.error(
              "Unable to delete record, please check internet connection or contact admin"
            );
          },
        });
        return;
      }
      //buck action
      $.ajax({
        url: _this.target,
        method: "delete",
        data: {
          targets: _this.records
        },
        success: function () {
          toastr.success("Records Deleted");
          deleteKyc.open = false;
          window.dispatchEvent(
            new CustomEvent("refresh-table", {
              detail: null,
            })
          );
        },
        error: function () {
          toastr.error(
            "Unable to delete records, please check internet connection or contact admin"
          );
        },
      });
    },
    onHandle: function (detail) {
      deleteKyc.open = true;
      this.target = detail.href;
      this.records = detail.records;
    },
    onClose: function () {
      deleteKyc.open = false;
    },
  };

  return _this;
};

window.deleteKycRecord = function (href, records) {
  window.dispatchEvent(
    new CustomEvent("delete-kyc", {
      detail: {
        href: href,
        records: records,
      },
    })
  );
};