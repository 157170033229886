import AutoNumeric from "autonumeric";
import { camelCase, defaultsDeep, forEach, isNull, merge } from "lodash";

export default class FundTransfer {
    constructor() {
        this.balance = 0;
        this.from = "";
        this.callback = null;
        this.amount = null;

        Spruce.store('fundTransfer', {
            open: false,
            alert: false,
            disable: true,
            idle: false,
        });

    }
    initialize(accounts) {
        this.accounts = accounts;
    }

    show(detail, callback) {
        this.$store.fundTransfer.open = true;
        this.from = detail.selected;
        this.balance = detail.balance;
        this.callback = callback;

        var selections = this.$refs.accounts;
        var index = 0;
        forEach(this.accounts, function (value, key) {
            if (value != detail.selected) {
                selections.options[index++] = new Option(value, key, false);
            }
        });

        var amount = this.$refs.amount;

        // if (AutoNumeric.getAutoNumericElement(amount) == null) {
        //     this.amount = new AutoNumeric(amount, {
        //         currencySymbol: amount.getAttribute('data-symbol'),
        //     });
        // }
    }
    onAmountChange() {
        if (!isNull(this.amount) && this.amount.getNumber() > 0) {
            this.$store.fundTransfer.disable = false;
            return;
        }
        this.$store.fundTransfer.disable = true;
    }
    transfered() {
        this.$store.fundTransfer.idle = false;
        this.$store.fundTransfer.open = false;
        this.$store.fundTransfer.disable = false;
    }

    failure() {
        this.$store.fundTransfer.disable = false;
        this.$store.fundTransfer.idle = false;
    }

    submit() {
        var _this = this;
        var form = this.$refs.form;
        this.$store.fundTransfer.idle = true;
        this.$store.fundTransfer.disable = true;
        var amount = this.$refs.amount.value;
        var options = this.$refs.accounts.options;
        var selected = options[options.selectedIndex].innerText;
        var fields = [...$(form).serializeArray(), { name: 'balance', value: amount }];

        $.ajax({
            url: `${form.action}/${this.from}`,
            data:fields,
            method: 'POST',
            success: (result) => {
                _this.transfered();
                notify('success', result);
                _this.callback(0, { selected: selected, amount: amount });
            },
            error: (result) => {
                _this.failure();
                
                notify('error', result.responseJSON);
                _this.callback(1, { selected: selected, amount: amount });
            }
        });
    }
}