(function(){
    var $method_form = $('#add_method');
    if($method_form.length>0){
        $method_form.on('submit', function(event){
            event.preventDefault();
            var action = $(this).attr('action');
            var method = $(this).attr('method');
            console.log(action, method);
            $.ajax({
                url: action,
                method: method,
                processData: false,
                contentType: false,
                data: new FormData(event.target),
                success: function(res){

                },
                error: function (err){

                }
            })
        });
    }

})();