window.traderOfMonth = function () {

    return {
        onSubmit: function () {
            var form = this.$refs.form;
            $.ajax({
                url: form.action,
                method: form.method,
                data: $(form).serialize(),
                headers: {
                    'X-CSRF-TOKEN': _token
                },  
                success: function (result) {
                    notify('success', result);
                },
                error: function (result) {
                    notify('error', result.responseJSON);
                }
            });
        }
    }
}