import Spruce from "@ryangjchandler/spruce";
import tailDatetime from "tail.datetime";


window.transactionHistory = function (element, startDate, stopDate) {
  Spruce.store("balance_table", {
    startDate: startDate,
    stopDate: stopDate,
    search: "",
    idle: true,
  });

  var table = $("#transaction-history-table").DataTable({
    serverSide: true,
    processing: true,
    ajax: {
      type: "GET",
      // data: function (data) {
      // },
    },
    columns: [
      {
        data: "transaction_id",
        name: "transaction_id",
        title: "Trans. ID",
        orderable: true,
        searchable: true,
        width: 20,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "requested_amount",
        name: "requested_amount",
        title: "Amount",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "created_at",
        name: "created_at",
        title: "Req. Date",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "updated_at",
        name: "updated_at",
        title: "Confirm Date",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "currency",
        name: "currency",
        title: "Currency",
        width: 10,
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      // {
      //   data: "method",
      //   name: "method",
      //   title: "Method",
      //   orderable: true,
      //   searchable: true,
      //   className: "text-center text-xs font-semibold",
      // },
      {
        data: "status",
        name: "status",
        title: "Status",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "action",
        name: "action",
        title: "",
        orderable: false,
        searchable: false,
        className: "text-center text-xs font-semibold",
      }
    ],
    autoWidth: false,
    responsive: true,
    language: {
      emptyTable: "No Transaction history",
      paginate: {
        previous:
          '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon icon-tabler icon-tabler-chevrons-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n                                <polyline points="11 7 6 12 11 17" />\n                                <polyline points="17 7 12 12 17 17" />\n                            </svg>',
        next:
          '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon icon-tabler icon-tabler-chevrons-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n                                <polyline points="7 7 12 12 7 17" />\n                                <polyline points="13 7 18 12 13 17" />\n                            </svg>',
      },
    },
    dom: "rtip",
    order: [[1, "desc"]],
    buttons: [
      {
        extend: "reload",
      },
      {
        name: 'pdfHtml5',
        extend: 'pdfHtml5',
        exportOptions: {
          columns: function (idx, data, node) {
            var $column = table.column(idx);
            return ($column.visible() && $(node).attr('data-visible-in-export') != 'false') || $(node).attr('data-force-export') == 'true';
          }
        },
        orientation: 'landscape',
        action: function (e, dt, button, config) {
          $.fn.DataTable.ext.buttons.pdfHtml5.action.call(this, e, dt, button, config);
        }
      },
      {
        extend: "excel",
      },
    ],
    initComplete: function () {
      Spruce.store("balance_table").idle = false;
      Spruce.watch("balance_table.search", (value) => {
        table.search(value, true, true, true).draw();
      });
    },
  });

  $.fn.DataTable.ext.buttons.reload = {
    className: 'buttons-reload',

    text: function (dt) {
      return '<i class="fa fa-refresh"></i> ' + dt.i18n('buttons.reload', 'Reload');
    },

    action: function (e, dt, button, config) {
      dt.draw(false);
    }
  };

  var rangeStart = tailDatetime("#start", {
    classNames: false,
    closeButton: false,
    rtl: "auto",
    animate: false,
    today: true,
    dateFormat: "dd-mm-YYYY",
    timeFormat: null,
  });

  var rangeStop = tailDatetime("#stop", {
    classNames: false,
    closeButton: false,
    rtl: "auto",
    animate: false,
    today: true,
    dateFormat: "dd-mm-YYYY",
    timeFormat: null,
  });

  rangeStart.on("change", function () {
    Spruce.store("balance_table").startDate = this.convertDate(
      this.fetchDate(),
      "dd-mm-YYYY"
    );
    rangeStart.dateStart = Spruce.store("balance_table").startDate;
  });

  rangeStop.on("change", function () {
    Spruce.store("balance_table").stopDate = this.convertDate(
      this.fetchDate(),
      "dd-mm-YYYY"
    );
  });

  return {
    options: false,
    exportPDF: function () {
      this.triggerThis("pdf");
    },
    reload: function () {
      table.ajax.reload();
    },
    exportExcel: function () {
      this.triggerThis("excel");
    },
    triggerThis: function (name) {
      table.buttons(`.buttons-${name}`).trigger();
    },
    onApprove: function (event) {
      const href = event.target.href;
      if (!href) return;
      $.ajax({
        url: href,
        method: "post",
        data: $.param({ approve: 1 }),
        headers: {
          'X-CSRF-TOKEN': window._token,
        },
        success: (data) => {
          console.log(data);
        },
        error: (data) => {
          console.error(data);
        }
      })
    },
    open: false,
    activeIndex: 0,
    menuItem: null,
    items: [],
    getIndex: function (id) {
      return id > 0 ? id - 1 : 0;
    },
    show: function (id) {
      var index = this.getIndex(id);
      if (!this.items[index]) return false;
      return this.items[index].open;
    },
    resetState: function (id) {
      var index = this.getIndex(id);
      if (!this.items[index]) return false;
      this.items[index].open = false;
    },
    onClickAway: function (id) {
      this.resetState(id);
    },
    onButtonEnter: function (id) {
      this.onButtonClick(id);
    },
    onButtonClick: function (id) {
      var index = this.getIndex(id);
      if (!this.items[index]) this.items[index] = { open: false };
      this.items[index].open = !this.items[index].open;
    },
    onPaid: function (id) {
      var target = this.$refs[`button_${id}`];
      var route = target.getAttribute('data-route');
      $.ajax({
        url: route,
        data: $.param({ paid: 1 }),
        method: "post",
        headers: {
          'X-CSRF-TOKEN': window._token,
        },
        success: (data) => {
          if (data.status === 1) {
            notify("success", data.message);
            setTimeout(() => {
              location.reload();
            }, 1000);
          }
        },
        error: (error) => {
          const data = error.responseJSON;
          notify("error", data.message);
        }
      })
    },
  
    confirmSent: function (url) {
      $.ajax({
        url: url,
        method: 'post',
        headers: {
          'X-CSRF-TOKEN': window._token
        },
        success: function (data) {
          if (data.status === 1) {
            notify("success", data.message);
            setTimeout(() => {
              location.reload();
            }, 1000);
          }
        },
        error: function (data) {
          const _data = data.responseJSON;
          notify("error", _data.message);
        }
      });
    },
    onCancel: function (id) {

    },
  };
};

window.pendingTransaction = function (element, startDate, stopDate) {
  Spruce.store("balance_table", {
    startDate: startDate,
    stopDate: stopDate,
    search: "",
    idle: true,
  });

  var table = $("#pending-transaction-table").DataTable({
    serverSide: true,
    processing: true,
    ajax: {
      type: "GET",
    },
    columns: [
      {
        data: "amount",
        name: "amount",
        title: "Amount",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "user_id",
        name: "user_id",
        title: "User",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "created_at",
        name: "created_at",
        title: "Requested Date",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "currency",
        name: "currency",
        title: "Currency",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "status",
        name: "status",
        title: "status",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "action",
        name: "action",
        title: "",
        orderable: false,
        searchable: false,
        className: "text-center text-xs font-semibold",
      },
    ],
    autoWidth: false,
    responsive: true,
    language: {
      emptyTable: "No Pending Transaction",
      paginate: {
        previous:
          '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon icon-tabler icon-tabler-chevrons-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n                                <polyline points="11 7 6 12 11 17" />\n                                <polyline points="17 7 12 12 17 17" />\n                            </svg>',
        next:
          '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon icon-tabler icon-tabler-chevrons-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n                                <polyline points="7 7 12 12 7 17" />\n                                <polyline points="13 7 18 12 13 17" />\n                            </svg>',
      },
    },
    dom: "rtip",
    order: [[1, "desc"]],
    buttons: [],
    initComplete: function () {
      // Spruce.store("balance_table").idle = false;
      // Spruce.watch("balance_table.search", (value) => {
      //   table.search(value, true, true, true).draw();
      // });
    },
  });

  $.fn.DataTable.ext.buttons.reload = {
    className: 'buttons-reload',

    text: function (dt) {
      return '<i class="fa fa-refresh"></i> ' + dt.i18n('buttons.reload', 'Reload');
    },

    action: function (e, dt, button, config) {
      dt.draw(false);
    }
  };

  var rangeStart = tailDatetime("#start", {
    classNames: false,
    closeButton: false,
    rtl: "auto",
    animate: false,
    today: true,
    dateFormat: "dd-mm-YYYY",
    timeFormat: null,
  });

  var rangeStop = tailDatetime("#stop", {
    classNames: false,
    closeButton: false,
    rtl: "auto",
    animate: false,
    today: true,
    dateFormat: "dd-mm-YYYY",
    timeFormat: null,
  });

  rangeStart.on("change", function () {
    Spruce.store("balance_table").startDate = this.convertDate(
      this.fetchDate(),
      "dd-mm-YYYY"
    );
    rangeStart.dateStart = Spruce.store("balance_table").startDate;
  });

  rangeStop.on("change", function () {
    Spruce.store("balance_table").stopDate = this.convertDate(
      this.fetchDate(),
      "dd-mm-YYYY"
    );
  });

  return {
    open: false,
    activeIndex: 0,
    menuItem: null,
    items: [],
    getIndex: function (id) {
      return id > 0 ? id - 1 : 0;
    },
    show: function (id) {
      var index = this.getIndex(id);
      if (!this.items[index]) return false;
      return this.items[index].open;
    },
    resetState: function (id) {
      var index = this.getIndex(id);
      if (!this.items[index]) return false;
      this.items[index].open = false;
    },
    onClickAway: function (id) {
      this.resetState(id);
    },
    onButtonEnter: function (id) {
      this.onButtonClick(id);
    },
    onButtonClick: function (id) {
      var index = this.getIndex(id);
      if (!this.items[index]) this.items[index] = { open: false };
      this.items[index].open = !this.items[index].open;
    },
    approveSent: function (url) {
      $.ajax({
        url: url,
        method: 'post',
        headers: {
          'X-CSRF-TOKEN': window._token
        },
        success:  (data) => {
          if (data.status === 1) {
            notify("success", data.message);
            setTimeout(() => {
              location.reload();
            }, 1000);
          }
        },
        error: (data) => {
          const _data = data.responseJSON;
          notify("error", _data.message);
        }
      });
    },
    onApprove: function (id) {
      var target = this.$refs[`button_${id}`];
      var route = target.getAttribute('data-route');

      $.ajax({
        url: route,
        data: $.param({ action: 1 }),
        method: "post",
        headers: {
          'X-CSRF-TOKEN': window._token,
        },
        success: (data) => {
          if (data.status === 1) {
            notify("success", data.message);
            setTimeout(() => {
              location.reload();
            }, 1000);
          }
        },
        error: (error) => {
          const data = error.responseJSON;
          notify("error", data.message);
        }
      })
    },

    onCancel: function (id) {

    },
    onDetail: function (id) {

    }
  };
}

window.showAccountDetail = function () {
  return {
    open: false,
    account_name: "",
    account_number: "",
    bank_name: "",
    bic_swift_code: "",
    country: "",
    iban_code: "",
    handle: function (detail) {
      var _t = "loading...";
      this.account_name = _t;
      this.account_number = _t;
      this.bank_name = _t;
      this.bic_swift_code = _t;
      this.country = _t;
      this.iban_code = _t;

      this.open = true;
      $.ajax({
        async: true,
        url: detail.url,
        headers: {
          'X-CSRF-TOKEN': window._token
        },
        success: (data) => {
          this.account_name = data.account_name;
          this.account_number = data.account_number;
          this.bank_name = data.bank_name;
          this.bic_swift_code = data.bic_swift_code;
          this.country = data.country;
          this.iban_code = data.iban_code;
        },
      })
    }
  }
}
