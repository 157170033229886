/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
$.ajaxSetup({
  headers: {
    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
  },
});

const intlTelInput = require("intl-tel-input");
window.validator = require('validator');

window.Spruce = require('@ryangjchandler/spruce');

import "alpinejs";
import tailDatetime from "tail.datetime";
import ClipboardJS from "clipboard";
import "print-this";
import tippy from "tippy.js";
require("bootstrap-pincode-input");
import "./users/notification_handler";
import "./users/profile";
import "./users/withdraws.js";
import "./users/telephone_input";
import "./users/trading_account";
import "./users/user-verification";
import "./users/settings";
import "./users/password-strength";
import "./users/partner-overview-chart";
import "./users/transaction_history";
import "./users/internal_transfer";
import "./users/card_payment";
import "./users/news";
import "./users/contact";
import "./users/promotion";
import "./users/bankwire";
import "./users/notification";
import "./users/locale.js";
import "./users/phone-verification";
import "./users/sponsoredAccount";
import "./users/traderOfMonth";
import "./users/Compliance";
import "./users/agent_table";

window.onload = function () {
  var resetButton = $('#crudTable_reset_button');
  if (resetButton.length > 0) {
    resetButton.prop('href', '#');
    resetButton.off('click');
    resetButton.on('click', function () {
      crud.table.ajax.reload();
    });
  }
};



import Application from './users/Application';

window.application = new Application;
window.app = window.application;

window.dispatch = function (name, data = null) {
  window.dispatchEvent(new CustomEvent(name, { detail: data }));
};

window.flash = function (title, message) {
  window.dispatchEvent(
    new CustomEvent("flash", { detail: { message: message, title: title } })
  );
};

$(function () {
  var clipboard = new ClipboardJS(".copy");
  clipboard.on("success", function (e) {
    console.info("Action:", e.action);
    console.info("Text:", e.text);
    console.info("Trigger:", e.trigger);
    var _tippy = tippy(e.trigger, {
      content: "Copied!",
      delay: 3000,
      onShown: function () {
        setTimeout(() => {
          _tippy.unmount();
          _tippy.destroy();
        }, 3000);
      },
    });
    _tippy.show();

    e.clearSelection();
  });

  var $countries = $("#countries");
  if ($countries.length > 0) {
    $countries.selectize({
      create: true,
    });
  }


  tailDatetime(".datetime", {
    classNames: false,
    closeButton: false,
    rtl: "auto",
    animate: false,
    today: true,
    dateFormat: "dd-mm-YYYY",
    timeFormat: null,
  });

  //email validator
  $(".input-indicator input").on("change", function () {
    const parent = $(this).parent();
    if (parent.hasClass("email")) {
      if (isEmail($(this).val())) {
        parent
          .find("i")
          .attr("class", "fas")
          .removeClass("d-none")
          .addClass("fa-check")
          .addClass("text-success");
      } else {
        parent
          .find("i")
          .attr("class", "fas")
          .removeClass("d-none")
          .addClass("fa-times-circle")
          .addClass("text-danger");
      }
    }
    if (parent.hasClass("plain")) {
      console.log($(this).val());
      if (isAlpha($(this).val())) {
        parent
          .find("i")
          .attr("class", "fas")
          .removeClass("d-none")
          .addClass("fa-check")
          .addClass("text-success");
      } else {
        parent
          .find("i")
          .attr("class", "fas")
          .removeClass("d-none")
          .addClass("fa-times-circle")
          .addClass("text-danger");
      }
    }
  });
});



import AutoNumeric from "autonumeric";

const $numeric = $('.numeric');
if ($numeric.length > 0) {
  $numeric.each((__, element) => {
    if (element) {
      var instance = new AutoNumeric(element, {
        currencySymbol: element.getAttribute('data-symbol') || "",
        minimumValue: element.getAttribute('min'),
        maximumValue: element.getAttribute('max') || 10000000000000,
        decimalPlacesRawValue: element.getAttribute('data-decimal-place') || null,
      });
      
      $(element).on('input', () => {
        $(`#${element.getAttribute('data-input-id')}`).val(instance.rawValue);
      });
    }
  });
}
