const { isNull, toInteger, isNumber } = require("lodash");
const { default: validator } = require("validator");

window.refund = function () {
    var _this = {
        init: function () {
            this.$refs.amount.setCustomValidity('Zero Amount specified');
        },
        refund: function () {
            var amount = this.$refs.amount;
            amount.reportValidity();

            if (validator.isNumeric(amount.value) && toInteger(amount.value) > 0) {
                const form = this.$refs.form;
                var url = $(form).attr('action');
                $.ajax({
                    type: "POST",
                    url: url,
                    dataType: 'json',
                    data: $(form).serialize(), // serializes the form's elements.
                    success: function (data) {
                        if (!isNull(data)) {
                            showNotification(data.type, data.message);
                        }
                    },
                    error: function (data) {
                        const json = data.responseJSON;
                        if (!isNull(json)) {
                            showNotification(json.type, json.message);
                        }
                    }
                });
            }
        },
        onInput: function () {
            var amount = this.$refs.amount;
            if (validator.isNumeric(amount.value) && toInteger(amount.value) <= 0) {
                this.$refs.amount.setCustomValidity('Zero Amount specified');
            }
            else if (!validator.isNumeric(amount.value)) {
                this.$refs.amount.setCustomValidity('Invalid amount specified');
            }
            else {
                this.$refs.amount.setCustomValidity("");
            }
        }
    }

    return _this;
}
