import * as echarts from "echarts";
import Spruce from "@ryangjchandler/spruce";
import tailDatetime from "tail.datetime";

window.partnerOverview = function (element) {
  Spruce.store("partner_overview", {
    waiting: false,
  });

  var chartDom = document.getElementById("partner-overview");
  var myChart = echarts.init(chartDom);
  var option;

  var colors = ["#5793f3", "#d14a61", "#675bba"];

  option = {
    color: colors,
    legend: {
      data: ["Deposit", "Commission"],
    },
    animation: false,
    tooltip: {},
    dataZoom: [
      {
        type: "inside",
        xAxisIndex: [0],
        start: 0,
        end: 100,
      },
      {
        type: "inside",
        yAxisIndex: [0],
        start: 0,
        end: 100,
      },
    ],
    grid: {
      left: "10%",
      right: "10%",
      bottom: 60,
      width: "auto",
      height: "auto",
    },
    xAxis: [
      {
        type: "category",
        axisTick: {
          alignWithLabel: false,
        },
        axisLine: {
          onZero: true,
        },
        axisPointer: {
          label: {
            formatter: function (params) {
              return (
                " " +
                params.value +
                (params.seriesData.length
                  ? "：" + params.seriesData[0].data
                  : "")
              );
            },
          },
        },
        data: [],
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "Client",
        min: "dataMin",
        max: "dataMax",
        interval: 100,
        position: "right",
        show: true,
        nameLocation: "middle",
        nameGap: 5,
        axisLabel: {
          formatter: "{value}",
        },
        splitLine: {
          show: true,
        },
        axisLine: {
          show: true,
        },
      },
      {
        type: "value",
        show: true,
        name: "$",
        min: 0,
        max: 32,
        interval: 100,
        position: "left",
        nameLocation: "middle",
        nameGap: 50,
        axisLabel: {
          formatter: "${value}",
        },
        axisLine: {
          show: true,
        },
        splitLine: {
          show: true,
        },
      },
    ],
    series: [
      {
        name: "Deposit",
        symbol: "roundRect",
        type: "line",
        smooth: true,
        data: [],
      },
      {
        name: "Commission",
        type: "line",
        smooth: true,
        data: [],
      },
    ],
  };

  option && myChart.setOption(option);

  $(window).on("resize ready load unload", function () {
    setTimeout(() => {
      myChart.resize();
    }, 300);
  });

  var table = $("#referralanalytictable-table").DataTable({
    serverSide: true,
    processing: true,
    ajax: {
      type: "GET",
      data: function (data) {
      },
    },
    columns: [
      {
        data: "id",
        name: "id",
        title: "Client ID",
        orderable: true,
        searchable: true,
        className: "text-center text-sm",
      },
      {
        data: "day",
        name: "day",
        title: "Day",
        orderable: true,
        searchable: true,
        className: "text-center text-sm",
      },
      {
        data: "Deposit",
        name: "Deposit",
        title: "Deposit",
        orderable: true,
        searchable: true,
        className: "text-center text-sm",
      },
      {
        data: "Commission",
        name: "Commission",
        title: "Commission",
        orderable: true,
        searchable: true,
        className: "text-center text-sm",
      },
    ],
    dom: "rtip",
    autoWidth: false,
    responsive: true,
    initComplete: function () {
      var partner_overview = Spruce.store("partner_overview");
      partner_overview.waiting = false;
    },
    drawCallback: function () {
      var api = this.api();
      setChartData(api.ajax.json().data, myChart);
    },
    pageLength: 8,
    language: {
      paginate: {
        previous:
          '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon icon-tabler icon-tabler-chevrons-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\
                    <polyline points="11 7 6 12 11 17" />\
                    <polyline points="17 7 12 12 17 17" />\
                  </svg>',
        next:
          '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon icon-tabler icon-tabler-chevrons-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\
              <polyline points="7 7 12 12 7 17" />\
              <polyline points="13 7 18 12 13 17" />\
        </svg>',
      },
    },
  });

  return {};
};

function setChartData(data, myChart) {
  var date = [];
  var deposit = [];
  var newClients = [];
  var withdrawals = [];
  var dw_diffs = [];
  var commissions = [];
  if (typeof data === "object") {
    data.forEach((element) => {
      date.push(element.day);
      newClients.push(element.new_clients);
      deposit.push(element.deposit);
      withdrawals.push(element.withdrawal);
      dw_diffs.push(element.dw_diff);
      commissions.push(element.commission);
    });
  }
  myChart.setOption({
    xAxis: [
      {
        data: date,
      },
    ],
    series: [
      {
        data: deposit,
      },
      {
        data: commissions,
      },
    ],
  });
}

window.partnerBalanceTable = function (element, startDate, stopDate) {
  Spruce.store("balance_table", {
    startDate: startDate,
    stopDate: stopDate,
    search: '',
    idle: true,
  });

  var table = $("#referralcommissions-table").DataTable({
    serverSide: true,
    processing: true,
    ajax: {
      type: "GET",
      data: function (data) {
        // for (var i = 0, len = data.columns.length; i < len; i++) {
        //   if (!data.columns[i].search.value) delete data.columns[i].search;
        //   if (data.columns[i].searchable === true)
        //     delete data.columns[i].searchable;
        //   if (data.columns[i].orderable === true)
        //     delete data.columns[i].orderable;
        //   if (data.columns[i].data === data.columns[i].name)
        //     delete data.columns[i].name;
        // }
        // delete data.search.regex;
      },
    },
    columns: [
      {
        data: "account",
        name: "account",
        title: "Account",
        orderable: true,
        searchable: true,
        className: "text-center text-sm",
      },
      {
        data: "created_at",
        name: "created_at",
        title: "Account creation date",
        orderable: true,
        searchable: true,
        className: "text-center text-sm",
      },
      {
        data: "commission",
        name: "commission",
        title: "Commission",
        orderable: true,
        searchable: true,
        className: "text-center text-sm",
      },
      {
        data: "is_confirmed",
        name: "is_confirmed",
        title: "Is Confirmed",
        orderable: true,
        searchable: true,
        className: "text-center text-sm",
      },
    ],
    autoWidth: false,
    responsive: true,
    language: {
      paginate: {
        previous:
          '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon icon-tabler icon-tabler-chevrons-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n                                <polyline points="11 7 6 12 11 17" />\n                                <polyline points="17 7 12 12 17 17" />\n                            </svg>',
        next:
          '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon icon-tabler icon-tabler-chevrons-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n                                <polyline points="7 7 12 12 7 17" />\n                                <polyline points="13 7 18 12 13 17" />\n                            </svg>',
      },
    },
    dom: "rtip",
    order: [[1, "desc"]],
    initComplete: function () {
      Spruce.store("balance_table").idle = false;
      Spruce.watch('balance_table.search', value => {
        table.search(value, true, true, true).draw();
      });
    },
  });



  var rangeStart = tailDatetime("#start", {
    classNames: false,
    closeButton: false,
    rtl: "auto",
    animate: false,
    today: true,
    dateFormat: "dd-mm-YYYY",
    timeFormat: null,
  });

  var rangeStop = tailDatetime("#stop", {
    classNames: false,
    closeButton: false,
    rtl: "auto",
    animate: false,
    today: true,
    dateFormat: "dd-mm-YYYY",
    timeFormat: null,
  });

  rangeStart.on("change", function () {
    Spruce.store("balance_table").startDate = this.convertDate(
      this.fetchDate(),
      "dd-mm-YYYY"
    );
    rangeStart.dateStart = Spruce.store("balance_table").startDate;
  });

  rangeStop.on("change", function () {
    Spruce.store("balance_table").stopDate = this.convertDate(
      this.fetchDate(),
      "dd-mm-YYYY"
    );
  });

  return {
    showRangeStart: function () { },
  };
};


window.referral = function () {
  return {
    withdraw() {
      const href = this.$refs.button.href;
      $.ajax({
        url: href,
        method: 'post',
        success: function (res) {
          notify('success', res);
        },
        error: function (res) {
          notify('error', res.responseJSON);
        }
      })
    }
  }
}