import {isNull} from "lodash";

window.notify = function (type, data) {
  window.dispatchEvent(
    new CustomEvent("notify", { detail: { type: type, data: data } })
  );
};


window.flashMessageHandler = function () {
  let data = {
    show: true,
    alerts: [],
    handleEvent: function (detail) {
      var alertData = {
        ...detail,
        show: true,
        remove: function () {
          this.show = false;
          setTimeout(() => {
            data.alerts.length = data.alerts.length - 1;
          }, 2000);
        },
      };
      this.alerts.push(alertData);
      this.setAutoRemove();
    },
    autoRemoveHandler: function () {
      var index = this.alerts.length - 1;
      if (this.alerts[index] !== undefined) {
        (this.alerts[index].show = false), (this.alerts.length = index);

        if (this.alerts.length > 0) {
          this.setAutoRemove();
          return;
        }
      }
    },
    setAutoRemove: function () {
      setTimeout(this.autoRemoveHandler.bind(this), 10000);
    },
  };

  return data;
};
