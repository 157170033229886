
window.bankwireForm = function()
{   
    var $select_bdr = $("#country");
    if ($select_bdr.length > 0) {
      $select_bdr.each(function () {
        var $this = $(this);
        $this.selectize({
          create: "text"
        });
      });
    }
    
    var _this = {

    };

    return _this;
}