import tinymce from "tinymce";
import Spruce from "@ryangjchandler/spruce";
import toastr from "toastr";

window.notificationSettings = function () {
  toastr.clear();
  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: "toast-top-center",
    preventDuplicates: true,
    showDuration: "1000",
    hideDuration: "10000",
    timeOut: "2000",
    extendedTimeOut: "1000",
  };

  var notification = Spruce.store("notification", {
    loading: true,
    editor: null,
    body: "",
    notifier_template: [],
    templates: [["0", "None"]],
  });

  var _this = {
    open: false,
    open1: false,
    selected: 0,
    in_disabled: true,
    value: 0,
    body: "",
    newTemplateName: "",
    template_id: "",
    options: [],
    selectedTemplate: "",
    init: function (token) {
      $.ajaxSetup({
        headers: {
          "X-CSRF-TOKEN": token,
        },
      });
      $.ajax({
        async: false,
        data: { init: true },
        success: function (res) {
          notification.templates = notification.templates.concat(
            Object.entries(res[2].templates)
          );
          var entries = Object.entries(res[1].notifiers);
          entries.forEach((value) => {
            _this.options.push({ name: value[0], value: value[1] });
            _this.selected = 0;
            _this.value = 0;
          });
          var templates = res[0].editor_template;
          notification.notifier_template = notification.notifier_template.concat(
            templates
          );
        },
      });
      _this.template_id = _this.options[0].name;

      tinymce.init({
        selector: "textarea",
        height: 300,
        plugins:
          "image print code preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount contextmenu colorpicker textpattern help",
        /* enable title field in the Image dialog*/
        image_title: true,
        theme: "modern",
        toolbar1:
          "'undo redo | link image | formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | code |",
        image_advtab: true,
        force_hex_style_colors: false,
        force_br_newlines: false,
        force_p_newlines: true,
        convert_newlines_to_brs: false,
        forced_root_block: 0,
        invalid_elements: "body",
        templates: notification.notifier_template,
        /* enable automatic uploads of images represented by blob or data URIs*/
        automatic_uploads: true,
        /*
          URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
          images_upload_url: 'postAcceptor.php',
          here we add custom filepicker only to Image dialog
        */
        file_picker_types: "image",
        /* and here's our custom image picker*/
        file_picker_callback: function (cb, value, meta) {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          /*
            Note: In modern browsers input[type="file"] is functional without
            even adding it to the DOM, but that might not be the case in some older
            or quirky browsers like IE, so you might want to add it to the DOM
            just in case, and visually hide it. And do not forget do remove it
            once you do not need it anymore.
          */

          input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {
              /*
                Note: Now we need to register the blob in TinyMCEs image blob
                registry. In the next release this part hopefully won't be
                necessary, as we are looking to handle it internally.
              */
              var id = "blobid" + new Date().getTime();
              var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(",")[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              /* call the callback and populate the Title field with the file name */
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
        init_instance_callback: function (editor) {
          $("#mceu_17").css({
            width: "auto",
          });
          notification.loading = false;
          notification.editor = editor;
          _this.choose(0);
        },
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      });
    },
    onSelected: function () {
      if (_this.options[_this.value]) {
        return _this.options[_this.value].value;
      }
    },
    onSelectClick: function () {
      this.open = !this.open;
    },
    onOptionSelect: function () {},
    onArrowUp: function () {
      if (this.value > 0) {
        this.value--;
      }
    },
    onArrowDown: function () {
      if (this.value < this.options.length - 1) {
        this.value++;
      }
    },
    onEscape: function () {
      this.open = false;
    },
    choose: function (selected) {
      this.value = selected;
      this.template_id = _this.options[_this.value].name;
      $.ajax({
        data: $.param([
          {
            name: "template",
            value: _this.template_id,
          },
        ]),
        success: function (res) {
          tinymce.activeEditor.setContent(res.body || "");
        },
        error: function (err) {
          console.error(err);
        },
      });
    },
    saveTemplate: function (url) {
      var param = [];

      param.push({ name: "title", value: _this.newTemplateName });
      param.push({
        name: "template",
        value: btoa(tinymce.activeEditor.getContent()),
      });

      $.ajax({
        url: url,
        method: "post",
        data: $.param(param),
        success: function (res) {
          if (res.templates) {
            notification.templates = Object.entries(res.templates);
            toastr.success("Template added Successfully");
          }
        },
        error: function (err) {
          if (err) {
            toastr.error("Unexpected error, check network");
          }
        },
      });
    },
    save: function (form) {
      tinymce.triggerSave();
      var action =
        form.action && String(form.action).replace("#", this.template_id);

      $.ajax({
        data: $(form).serialize(),
        method: "put",
        url: action,
        success: function (res) {
          toastr.success("Update Successful");
        },
        error: function (err) {
          toastr.error("Update Failure");
        },
      });
    },
    selectionChanged: function (url) {
      if (_this.selectedTemplate !== 0) {
        $.ajax({
          url: url,
          data: $.param({ template: _this.selectedTemplate }),
          success: function (res) {
            tinymce.execCommand("mceInsertContent", true, res.template);
          },
          error: function () {
            toastr.error("Unexpected error, check network");
          },
        });
      }
    },
    deleteSelected: function (url) {
      this.open1 = true;
      $.ajax({
        url: url,
        headers: {
          "X-CSRF-TOKEN": token.value,
        },
        method: "delete",
        data: $.param({ template: _this.selectedTemplate }),
        success: function (res) {
          if (res.templates) {
            notification.templates = Object.entries(res.templates);
            toastr.success("Template deleted Successfully");
          }
        },
        error: function (err) {
          if (err) {
            toastr.error("Unexpected error, check network");
          }
        },
      });
    },
  };

  return _this;
};
