<template>
  <div class="chart">
    <div ref="chartRef" class="container px-2 py-2.5"></div>
  </div>
</template>

<script>
import Vue from 'vue';
import Highcharts from "highcharts";
import axios from "axios";


export default {
  mounted() {
    const option = this.option;
    const ref = this.$refs.chartRef;
    const config = {
      headers: {
        "X-CSRF-TOKEN": window._token,
      },
    };

    const volumes = axios.get("/api/ib/volumes", config);
    const commissions = axios.get("/api/ib/commission", config);

    axios
      .all([volumes, commissions])
      .then(
        axios.spread((...responses) => {
          option.series[0].data = responses[0].data;
          option.series[1].data = responses[1].data;
          Highcharts.chart(ref, option);
        })
      )
      .catch((errors) => {
        if (errors) {
          // const handle = setInterval(() => {
          //   if (isOnline) location.reload();
          //   clearInterval(handle);
          // }, 100000);
        }
      });
  },
  data() {
    return {
      option: {
        chart: {
          zoomType: "xy",
        },
        title: {
          text: "Monthly Performance",
          align: "center",
        },

        xAxis: [
          {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            crosshair: true,
          },
        ],
        yAxis: [
          {
            labels: {
              format: "{value}$",
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
            title: {
              text: "Commission",
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
            opposite: true,
          },
          {
            // Secondary yAxis
            gridLineWidth: 0,
            title: {
              text: "Lot",
              style: {
                color: Highcharts.getOptions().colors[0],
              },
            },
            labels: {
              format: "{value}",
              style: {
                color: Highcharts.getOptions().colors[0],
              },
            },
          },
        ],
        tooltip: {
          shared: true,
        },
        legend: {
          layout: "vertical",
          align: "left",
          x: 80,
          verticalAlign: "top",
          y: 55,
          floating: true,
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || // theme
            "rgba(255,255,255,0.25)",
        },
        series: [
          {
            name: "Lot",
            type: "column",
            yAxis: 1,
            data: [],
            tooltip: {
              valueSuffix: " lots",
            },
          },
          {
            name: "Commission",
            type: "spline",
            data: [],
            tooltip: {
              valueSuffix: " $",
            },
          },
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  floating: false,
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                  x: 0,
                  y: 0,
                },
                yAxis: [
                  {
                    labels: {
                      align: "right",
                      x: 0,
                      y: -6,
                    },
                    showLastLabel: false,
                  },
                  {
                    labels: {
                      align: "left",
                      x: 0,
                      y: -6,
                    },
                    showLastLabel: false,
                  },
                ],
              },
            },
          ],
        },
      },
    };
  },
};
</script>

<style scoped>
.container {
  height: 400px;
}
</style>

<style>
.highcharts-credits {
  display: none;
}
</style>