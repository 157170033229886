import AutoNumeric from 'autonumeric';

window.agentTable = (element) => {

  var table = $("#agent-table").DataTable({
    serverSide: true,
    processing: true,
    ajax: {
      type: "GET",
    },
    columns: [
      {
        data: "agent",
        name: "agent",
        title: "Agents",
        orderable: true,
        searchable: true,
        width: 60,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "price_rate",
        name: "price_rate",
        title: "Price",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "limit_available",
        name: "limit_available",
        title: "Limit/Available",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "payment_method",
        name: "payment_method",
        title: "Payment",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
      {
        data: "action",
        name: "action",
        title: "",
        orderable: true,
        searchable: true,
        className: "text-center text-xs font-semibold",
      },
    ],
    autoWidth: false,
    responsive: true,
    language: {
      emptyTable: "No Agent, please contact customer care",
      paginate: {
        previous:
          '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon icon-tabler icon-tabler-chevrons-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n                                <polyline points="11 7 6 12 11 17" />\n                                <polyline points="17 7 12 12 17 17" />\n                            </svg>',
        next:
          '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon icon-tabler icon-tabler-chevrons-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">\n                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>\n                                <polyline points="7 7 12 12 7 17" />\n                                <polyline points="13 7 18 12 13 17" />\n                            </svg>',
      },
    },
    dom: "rtip",
    order: [[1, "desc"]],
    initComplete: function () {

    },
  });
  return {
    onSearch: (target) => {
      table.search(target.value || "", false, true, true).draw();
    },
    onCurrencyChange: function (target) {
      this.onSearch(target);
    }
  }
}

window.handleDeposit = (rate, available) => {
  return {
    rate: rate,
    show: false,
    _paidAmt: null,
    done: false,
    state: false,
    amount: 0.0,
    _expectedAmt: null,
    init: function () {
      var expectedAmt = document.getElementById('expected-amt');
      var paidAmt = document.getElementById('paid-amt');
      if (AutoNumeric.getAutoNumericElement(expectedAmt) == null) {
        this._expectedAmt = new AutoNumeric(expectedAmt, {
          maximumValue: available * rate,
          decimalPlaces: 0,
        });
      }
      if (AutoNumeric.getAutoNumericElement(paidAmt) == null) {
        this._paidAmt = new AutoNumeric(paidAmt, {
          maximumValue: available,
          decimalPlaces: 0,
        });
      }
    },
    handle: function () {
      this.show = true;
    },
    onChanged: function () {
      if (this._paidAmt != null && this._expectedAmt != null) {
        return this._expectedAmt.set(this._paidAmt.getNumber() * this.rate);
      }
    },
    onReceiveChanged: function () {
      if (this._paidAmt != null && this._expectedAmt != null) {
        return this._paidAmt.set(this._expectedAmt.getNumber() / this.rate);
      }
    },
    submit: function () {
      var form = this.$refs.form;
      var data = $.param({ amount: this._paidAmt.getNumber() });
      var _amountElem = this.$refs.amount;
      var _amount = null;
      if (AutoNumeric.getAutoNumericElement() == null) {
        _amount = new AutoNumeric(this.$refs.amount, { currencySymbol: _amountElem.getAttribute('data-symbol') });
      }
      $.ajax({
        url: form.action,
        method: "POST",
        headers: {
          'X-CSRF-TOKEN': window._token,
        },
        data: data,
        success: (response) => {
          if (response == 1) {
            _amount.set(this._expectedAmt.getNumber());
          }
          this.state = false;
          this.done = true;
        },
        error: (response) => {
          console.error(response.responseJSON);
          this.state = false;
          this.done = false;
        }
      });
    }
  }
}
