import { bindAll, camelCase, capitalize, isUndefined, toLower } from "lodash";
import AccountItem from "./AccountItem";


export default class DemoAccounts {
    constructor() {
        this.items = [];
    }
    initialize() {
        if (!isUndefined(this.$refs.accounts)) {
            var children = this.$refs.accounts.children;
            children.forEach(element => {
                this.items[element.id] = new AccountItem();
            });
        }
    }

    setAccount(id) {
        this.items[id] = new AccountItem();
        return this.items[id];
    }
}