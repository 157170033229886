var ss = require("simple-ajax-uploader");
var bytes = require("bytes");
import "../datepicker-full";
import moment from "moment";
import { now } from "moment";
import Tagify from '@yaireo/tagify'

window.boxMailer = function () {
  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    preventDuplicates: true,
    positionClass: "toast-top-center",
    showDuration: "1000",
    hideDuration: "10000",
    timeOut: "2000",
    extendedTimeOut: "1000",
  };

  var mailer = Spruce.store("mailer", {
    notifier_template: [],
    templates: [["0", "None"]],
    progressbar: false,
    files: [],
    selected_month: "",
    selected_time: "",
    mail_interval: 0,
  });

  var _this = {
    persist: false,
    open: false,
    showModal: false,
    detail: null,
    emails: "",
    scheduleMenu: false,
    subject_readonly: false,
    subject: "",
    options: [],
    selectedTemplate: "",
    maxsize: 100000,
    dropzone: null,
    previewTemplate: "template",
    upload: "upload",
    scheduleDialog: false,
    totaluploadprogress: function (progress) {
      $(`#${_this.fileprogress}`).css({
        width: progress + "%",
      });
    },
    init: function (token) {
      mailer.selected_month = moment(now()).format("MMM D, Y");
      mailer.selected_time = moment(now()).format("h:m A");
      $.ajaxSetup({
        headers: {
          "X-CSRF-TOKEN": token,
        },
      });

      const elem = document.querySelector("#inline");
      new Datepicker(elem, {
        minDate: Date.now(),
      });

      elem.addEventListener("changeDate", function (e) {
        mailer.selected_month = moment(e.detail.date).format("MMM D, Y");
      });

      (window.onbeforeunload = function () {
        if (mailer.files.length > 0) {
          mailer.files.forEach((__, index) => {
            _this.onStealthDelete(index);
          });
        }
      }),
        new ss.SimpleUpload({
          button: "upload", // file upload button
          Dropzone: "composer",
          url: "/admin/uploads",
          name: "uploadedFile",
          customHeaders: {
            "X-CSRF-TOKEN": token,
          },
          multipart: true,
          disabledClass: "disabled",
          responseType: "json",
          allowedExtensions: [],
          maxSize: _this.maxsize,
          startXHR: function () {
            var progressBar = document.getElementById("processbar");
            this.setProgressBar(progressBar);
          },
          onSubmit: function () {
            mailer.progressbar = true;
          },
          onSizeError: function () {
            toastr.error("Files may not exceed " + bytes(_this.maxsize));
          },
          onExtError: function () {
            toastr.error("Please select a PNG, JPG, or GIF image.");
          },
          onComplete: function (filename, response) {
            mailer.progressbar = false;
            if (!response) {
              toastr.error("Unable to upload file.");
              return;
            }
            if (response.success === true) {
              mailer.files.push({
                name: filename,
                size: bytes(response.size),
              });
            } else {
              if (response.msg) {
                toastr.info(msg);
              } else {
                toastr.error(
                  "Unable to upload file.  Sorry, please try again."
                );
              }
            }
          },
          onError: function (response) {
            console.log(response);
            toastr.error(
              "Server error: unable to upload file.  Sorry, please try later."
            );
          },
        });

      $.ajax({
        url: "notifications-settings",
        async: false,
        data: { init: true },
        success: function (res) {
          mailer.templates = mailer.templates.concat(
            Object.entries(res[2].templates)
          );
          var entries = Object.entries(res[1].notifiers);
          entries.forEach((value) => {
            _this.options.push({ name: value[0], value: value[1] });
            _this.selected = 0;
            _this.value = 0;
          });
          var templates = res[0].editor_template;
          mailer.notifier_template = mailer.notifier_template.concat(templates);
        },
      });

      tinymce.init({
        selector: "textarea",
        height: 250,
        plugins:
          "image print code preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount contextmenu colorpicker textpattern help",
        /* enable title field in the Image dialog*/
        image_title: true,
        theme: "modern",
        toolbar1:
          "undo | redo | link image | formatselect | bold italic strikethrough forecolor backcolor | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent",
        image_advtab: true,
        force_hex_style_colors: false,
        force_br_newlines: false,
        force_p_newlines: true,
        convert_newlines_to_brs: false,
        forced_root_block: 0,
        invalid_elements: "body",
        templates: mailer.notifier_template,
        /* enable automatic uploads of images represented by blob or data URIs*/
        automatic_uploads: true,
        file_picker_types: "file, image",
        /* and here's our custom image picker*/
        file_picker_callback: function (cb, value, meta) {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {
              /*
                Note: Now we need to register the blob in TinyMCEs image blob
                registry. In the next release this part hopefully won't be
                necessary, as we are looking to handle it internally.
              */
              var id = "blobid" + new Date().getTime();
              var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(",")[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              /* call the callback and populate the Title field with the file name */
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
        init_instance_callback: function (editor) {
          // $("#mceu_17").css({
          //   width: "auto",
          // });
          // var $menubar = $(".mce-menubar");
          // if ($menubar.length > 0) {
          //   $menubar.each((__, element) => {
          //     console.log($(element).position());
          //   });
          //mce-menu
          // }
        },
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      });
    },
    handleEvent: function (detail) {
      this.showModal = true;
      this.detail = detail;
      this.emails = detail.emails.map((value) => { try { return $(value).text() } catch (__) { return value } }).join();
    },
    onSelected: function () {
      if (_this.options[_this.value]) {
        return _this.options[_this.value].value;
      }
    },
    onSelectClick: function () {
      this.open = !this.open;
    },
    choose: function (selected) {
      this.value = selected;
      this.template_id = _this.options[_this.value].name;
      if (_this.template_id !== "custom_mail") {
        _this.subject_readonly = true;
        _this.subject = _this.options[_this.value].value;
        $.ajax({
          url: "notifications-settings",
          data: $.param([
            {
              name: "template",
              value: _this.template_id,
            },
          ]),
          success: function (res) {
            tinymce.activeEditor.setContent(res.body || "");
          },
          error: function (err) {
            toastr.error("Unable to load notification content");
          },
        });
      } else {
        tinymce.activeEditor.setContent("");
        _this.subject_readonly = false;
        _this.subject = "";
      }
    },
    selectionChanged: function (url) {
      if (_this.selectedTemplate !== 0) {
        $.ajax({
          url: url,
          data: $.param({ template: _this.selectedTemplate }),
          success: function (res) {
            tinymce.execCommand("mceInsertContent", true, res.template);
          },
          error: function () {
            toastr.error("Unexpected error, check network");
          },
        });
      }
    },
    onStealthDelete: function (index) {
      $.ajax({
        url: "/admin/uploads/delete",
        method: "delete",
        data: $.param([{ name: "file", value: mailer.files[index].name }]),
        success: function () {
          mailer.files.splice(index, 1);
        },
      });
    },
    onDelete: function (index) {
      $.ajax({
        url: "/admin/uploads/delete",
        method: "delete",
        data: $.param([{ name: "file", value: mailer.files[index].name }]),
        success: function () {
          mailer.files.splice(index, 1);
        },
        error: function (response) {
          toastr.error("Unable to perform this operation");
        },
      });
    },
    submit: function () {
      if (_this.subject === "") {
        toastr.error("Please provide a subject");
        return;
      }
      $.ajax({
        url: "mailto",
        method: "post",
        data: $.param([
          { name: "subject", value: _this.subject },
          { name: "id", value: [...this.detail.sendto] },
          { name: "message", value: tinymce.activeEditor.getContent() },
          { name: "attachments", value: JSON.stringify(mailer.files) },
        ]),
        success: function () {
          toastr.success("Sent successful");
        },
        error: function () {
          toastr.error("Failure, Can't send mail");
        },
      });
    },
    scheduleSend: function () {
      var ajaxPromise = _this.schedule();
      if (ajaxPromise) {
        ajaxPromise
          .done(function () {
            toastr.success(
              "Scheduled at " +
              _this.scheduleDateTime().format("MMM D, Y h:m A")
            );
            _this.submit();
          })
          .fail(function () {
            toastr.error("Failure, can't schedule mail");
          });
      }
    },
    schedule: function () {
      if (_this.subject === "") {
        toastr.error("Please provide a subject");
        return;
      }
      return $.ajax({
        url: "mail/schedule",
        method: "post",
        data: $.param([
          { name: "subject", value: _this.subject },
          { name: "id", value: [...this.detail.sendto] },
          { name: "message", value: tinymce.activeEditor.getContent() },
          { name: "attachments", value: JSON.stringify(mailer.files) },
          { name: "persist", value: _this.persist },
          { name: "interval", value: _this.persist ? mailer.mail_interval : 0 },
          {
            name: "schedule_at",
            value: _this.scheduleDateTime().toString(),
          },
        ]),
        error: function () {
          toastr.error("Failure, can't schedule mail");
        },
      });
    },
    scheduleDateTime: function () {
      var date = moment(mailer.selected_month, "MMM D, Y");
      var time = moment(mailer.selected_time, "h:m A");
      return date.add({
        hours: time.hours(),
        minutes: time.minutes(),
        seconds: time.seconds(),
      });
    },
  };

  return _this;
};

window.mailer = function (type, mails, emails = []) {
  window.dispatchEvent(
    new CustomEvent("box-mailer", { detail: { type: type, sendto: mails, emails: emails } })
  );
};
