import AutoNumeric from 'autonumeric';
import { isEqual, isNull, isUndefined } from 'lodash';

export default class AccountItem {
    constructor() {
        this.profit = null;
        this.balance = null;
        this.leverage = null;
        this.hasBalance = false;
    }
    initialize(id) {
        var _this = this;

        var balance = this.$refs.balance;
        if (AutoNumeric.getAutoNumericElement(balance) == null) {
            this.balance = new AutoNumeric(balance, {
                currencySymbol: balance.getAttribute('data-symbol')
            });
        }

        var profit = this.$refs.profit;
        if (AutoNumeric.getAutoNumericElement(profit) == null) {
            this.profit = new AutoNumeric(profit, {
                currencySymbol: profit.getAttribute('data-symbol')
            });
        }

        var leverage = this.$refs.leverage;
        if (AutoNumeric.getAutoNumericElement(leverage) == null) {
            this.leverage = new AutoNumeric(leverage, {
                currencySymbol: '1:',
                decimalPlaces: 0,
                unformatOnSubmit: true,
            });
        }

        Spruce.store(`_${id}`, {
            show: false,
            hasBalance: _this.getBalance() > 0
        });

        window.addEventListener('fundsuccess', ({ detail }) => {
            if (isEqual(_this.$refs.item.id, detail.account)) {
                _this.setBalance(this.getBalance() + detail.amount);
                if (!isNull(application.stats) && !isUndefined(application.stats)) {
                    if (application.stats.getWalletBalance() > 0) {
                        application.$store.stats.walletBalance = true;
                    }
                }
            }
        });

        window.addEventListener('transferwalletsuccess', ({ detail }) => {
            if (isEqual(_this.$refs.item.id, detail.account)) {
                _this.setBalance(_this.getBalance() - detail.amount);

                if (!isNull(application.stats) && !isUndefined(application.stats)) {
                    if (application.stats.getWalletBalance() > 0) {
                        application.$store.stats.walletBalance = false;
                    }
                }
            }
        });

        dispatch('initstat');
    }
    getWalletBalance() {
        if (!isNull(application.stats) && !isUndefined(application.stats)) {
            if (application.stats.getWalletBalance() > 0) {
                return application.stats.getWalletBalance();
            }
        }
        return 0.0;
    }

    setBalance(value) {
        this.balance.set(value);
        this.$store[`_${this.$refs.item.id}`].hasBalance = (this.getBalance() > 0);
    }

    onToggleButton() {
        this.show = !this.show;
    }
    updateBalance(value) {
        this.setBalance(value + this.getBalance());
    }

    setProfit(value) {
        this.profit.set(value);
    }

    setLeverage(value) {
        this.leverage.set(value);
    }

    getBalance() {
        return this.balance.getNumber();
    }

    getProfit() {
        return this.profit.getNumber();
    }

    getLeverage() {
        return this.leverage.getFormatted();
    }

    addMoney() {
        dispatch('add-money', { selected: this.$refs.item.id, balance: this.getBalance() });
    }
    fundAccount() {
        if (application.stats.getWalletBalance() > 0) {
            dispatch('fund-account', { selected: this.$refs.item.id, balance: application.stats.getWalletBalance() });
        }
    }

    tranferToAccount() {
        var _this = this;
        var payload = { selected: this.$refs.item.id, balance: this.getBalance() };
        application.fundTransfer.show(payload, function (err, result) {
            if (!err) {
                var selected = application.liveAccounts.items[result.selected];
                if (!isNull(selected) && !isUndefined(selected)) {
                    selected.updateBalance(result.amount);
                    _this.setBalance(_this.getBalance() - result.amount);
                }
            }
        });
    }

    transferToWallet() {
        dispatch('wallet-transfer', { account: this.$refs.item.id, balance: this.getBalance() });
    }

    changeLeverage(leverages) {
        dispatch('leverage-edit', { id: this.$refs.item.id, choosen: this.getLeverage(), leverages: leverages });
    }

    onDelete(url) {
        $.ajax({
            url: url,
            method: "delete",
            success: function (response) {
                notify("success", response);
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            },
            error: function (response) {
                notify('error', response.responseJSON);
            }
        })
    }

    onChangeLeverage() {
        var _this = this;
        var form = this.$refs.leverageForm;
        var options = this.$refs.selections.options;
        var leverage = options[options.selectedIndex].value;
        $.ajax({
            url: `${form.action}`,
            method: form.method,
            data: $(form).serialize(),
            success: function (result) {
                notify('success', result);
                _this.setLeverage(leverage);
            },
            error: function (result) {
                notify('error', result.responseJSON);
            }
        });
    }
}