window.sponsoredPlan = function () {
    return {
        onSelected: function (target) {
            var form = this.$refs.form;
            $.ajax({
                url: form.action,
                method: 'POST',
                data: $.param({ 'account': target.getAttribute('data-id') }),
                headers: {
                    'X-CSRF-TOKEN': _token
                },
                success: function (result) {
                    notify('success', result);
                    window.location.href = "/user/dashboard";
                },
                error: function (result) {
                    notify('error', result.responseJSON);
                }
            })
        },
    }
}